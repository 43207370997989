import React, { useState} from "react";
import "../styles/main.scss";
import Header from "./Header";
import Footer from "./Footer";
import MainFooter from "./MainFooter";
import Navigation from "./Navigation";
import { withTranslation} from "react-i18next";

function PageNotFound(props) {
  const [toggleNavigation, setToggleNavigation] = useState(false)
  const [toggleHelp, setToggleHelp] = useState(false)

  return (
    <div className={`container articlepage help ${toggleNavigation ? " has-navigation" : ""} ${toggleHelp ? " has-help" : ""}`}>
      {/* <Alert /> */}
      <Header {...props} />
      <main>
        <div className="main-content">
          <article>
            <h1>404 not found</h1>
            <p>
              Sidan finns inte.
          </p>
          </article>
        </div>
        <MainFooter setToggleNavigation={setToggleNavigation} toggleNavigation={toggleNavigation} setToggleHelp={setToggleHelp} toggleHelp={toggleHelp} />
      </main>
      <Navigation toggleNavigation={toggleNavigation} />
      <Footer setToggleNavigation={setToggleNavigation} toggleNavigation={toggleNavigation} setToggleHelp={setToggleHelp} toggleHelp={toggleHelp} />
    </div>
  );
}
export default withTranslation()(PageNotFound);
