import React, { useState, useEffect } from "react";
import "../styles/main.scss";
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";
import Footer from "./Footer";
import Navigation from "./Navigation";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import config from "../utils/config";

function SelectDocsType(props) {
  const { t } = props;
  console.log(props.i18n.language);
  const [toggleNavigation, setToggleNavigation] = useState(false);
  const [toggleHelp, setToggleHelp] = useState(false);
  // console.log("props-->", props);
  return (
    <div
      className={`container guide selectdocstype ${
        toggleNavigation ? " has-navigation" : ""
      } ${toggleHelp ? " has-help" : ""}`}
    >
      <main>
        <MainHeader {...props} hideBack={true} />
        <div className="main-content">
          <h1>{t("useSignator")}</h1>
          <p className="button-group__centered">
            <Link
              to="/bli-kund-digital-arsredovisning"
              className="btn btn-primary"
              onClick={()=> config.LOCAL_FORAGE.setItem("document_type", "yearly_revenue")}
            >
              {t("yearlyRevenue")}
            </Link>
          </p>
          <p className="button-group__centered">
            <Link
              to="/bli-kund-digital-arsredovisning"
              className="btn btn-primary"
              onClick={()=> config.LOCAL_FORAGE.setItem("document_type", "other_document")}
            >
              {t("otherDocument")}
            </Link>
          </p>
        </div>
        <MainFooter
          setToggleNavigation={setToggleNavigation}
          toggleNavigation={toggleNavigation}
          setToggleHelp={setToggleHelp}
          toggleHelp={toggleHelp}
        />
      </main>
      <Footer
        setToggleNavigation={setToggleNavigation}
        toggleNavigation={toggleNavigation}
        setToggleHelp={setToggleHelp}
        toggleHelp={toggleHelp}
      />
      <Navigation toggleNavigation={toggleNavigation} />
    </div>
  );
}
export default withTranslation()(SelectDocsType);
