import React, { useState,useEffect } from "react";
import "../styles/main.scss";
import "../styles/components/_modal.scss";
import apicall from "../apis/apicall";
import helpers from "../utils/helpers";
import { withTranslation } from "react-i18next";
import config from "../utils/config";
function UpdateModal(props) {
  console.log(props);
  const { t, handleClose, member, isAccountantModal } = props;
  const [email, setemail] = useState(
    member["email"] && member["email"] != "null" ? member["email"] : ""
  );
  const [mobile, setmobile] = useState(
    member.mobile && member.mobile != "null" ? member.mobile : ""
  );

  const [document_type, setdocument_type] = useState();

  const fetchDocumentType = async () => {
    await config.LOCAL_FORAGE.getItem("document_type").then((resp) => {
      setdocument_type(resp);
    });
  };

  useEffect(() => {
    fetchDocumentType();
  });

  const handleSubmit = (invite) => {
    console.log("handleSubmit", invite);
    // setLoading(true);
    if (document_type === "other_document") {
      apicall
        .updateNewRevMember(member.id, email, mobile, invite, t)
        .then((resp) => {
          // setLoading(false);
          if (resp) {
            console.log(resp);
            // helpers.notify(t('saved'));
            handleClose(null);
          } else {
            helpers.notify(t("updatefailed"));
          }
        });
    } else {
      apicall
        .updateRevMember(member.id, email, mobile, invite, t)
        .then((resp) => {
          // setLoading(false);
          if (resp) {
            console.log(resp);
            // helpers.notify(t('saved'));
            handleClose(null);
          } else {
            helpers.notify(t("updatefailed"));
          }
        });
    }
    // e.preventDefault();
  };

  return (
    //remove inline CSS it just for demo
    <div className="app">
      <div className="user-form" key={`form`} style={{ border: "none" }}>
        <h1>{t("editTitle")}</h1>
        <div className="form-group">
          <label>{t("role")}</label>
          <p>{member.role}</p>
        </div>
        <div className="form-group">
          <label for="fname">{t("firstname")}</label>
          <p>{member.firstName}</p>
        </div>
        <div className="form-group">
          <label for="lname">{t("lastname")}</label>
          <p>{member.lastName}</p>
        </div>
        <div className="form-group">
          <label for="email">{t("emailaddress")}</label>
          <input
            type="email"
            id="email"
            value={email}
            required
            onChange={(event) => setemail(event.target.value)}
          />
        </div>
        <div className="form-group">
          <label for="mobile">
            {t("mobilenumber")} ({t("optional")})
          </label>
          <input
            type="text"
            id="mobile"
            name="mobile"
            // value={member.mobile}
            value={mobile ? mobile : ""}
            maxLength={10}
            onChange={(event) => {
              !isNaN(event.target.value) && setmobile(event.target.value);
            }}
          />
        </div>
        {isAccountantModal && member.role !== "LE" && member.signingLink && (
          <button
            style={{ margin: "5px 4px" }}
            className="btn btn-success"
            onClick={() => handleSubmit(true)}
          >
            {t("saveandinvite")}
          </button>
        )}

        {!isAccountantModal && member.signingLink && (
          <button
            style={{ margin: "5px 4px" }}
            className="btn btn-success"
            onClick={() => handleSubmit(true)}
          >
            {t("saveandinvite")}
          </button>
        )}

        {isAccountantModal && member.role !== "LE" && member.signingLink && (
          <div>
            <a
              href={member.signingLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button style={{ margin: "5px 4px" }} className="btn btn-success">
                {t("to_signing")}
              </button>
            </a>
          </div>
        )}
        {!isAccountantModal && member.signingLink && (
          <div>
            <a
              href={member.signingLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button style={{ margin: "5px 4px" }} className="btn btn-success">
                {t("to_signing")}
              </button>
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
export default withTranslation()(UpdateModal);
