import React from 'react'

export default function Navigation(props) {
    // console.log(props)
    return (
        <nav className="main-navigation">
            <ul>
                <li className="start"><a href="/">Hem</a></li>
                <li className="guide"><a href="/guide">Guide</a></li>
                <li className="about"><a href="/om-signator">Om Signator</a></li>
                <li className="services"><a href="/tjanster-och-priser">Tjänster &amp; priser</a></li>
                {/* <li className="help"><a href="/hjalp">Hjälp</a></li> */}
                {/* <li className="faq"><a href="/vanliga-fragor">Vanliga frågor</a></li> */}
                <li className="termsandconditions"><a href="/allmanna-villkor">Allmänna villkor</a></li>
                <li className="privacypolicy"><a href="/integritetspolicy">Integritetspolicy &amp; GDPR</a></li>
                <li className="contact spacing"><a href="/kontakt">Kontakt</a></li>
            </ul>
        </nav>
    )
}
