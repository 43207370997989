import React, { useState, useEffect,useRef } from "react";
import apicall from "../apis/apicall";
import OverlaySpinner from "./OverlaySpinner";
import helpers from "../utils/helpers"
export default function PreviewDocument(props) {
  // const [path, setpath] = useState()
  console.log(props)
  const path = helpers.decodePath(props.match.params.path);
  const [fileURL, setfileURL] = useState();
  const [loading, setLoading] = useState(true);
  const refContainer = useRef();

  useEffect(() => {
    fetchFile();
  }, [path]);

  const fetchFile = (type) => {
    console.log(path);
    apicall.getFile(path).then((resp) => {
      setLoading(false);
      if (resp) {
        let binaryString = atob(resp);
        let binaryLen = binaryString.length;
        let bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          let ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
        const file = new Blob([bytes], { type: "application/pdf" });
        const objectUrl = URL.createObjectURL(file);
        console.log("the object url is", objectUrl);
        setfileURL(objectUrl);
        refContainer.current.click();
      }
    });
  };

  console.log(refContainer.current)

  return (
    <div
      className={`container detailview previewdocument ${loading && "has-spinner has-overlay"}`}
    >
      {loading && <OverlaySpinner/>}
      {/* <embed src={fileURL} type="application/pdf" style={{width:"100vw",height:"100vh"}} /> */}
      {/* <iframe
              title="Signator"
              src={fileURL}
              style={{ width: "100vh", height: "100vh" }}
              frameborder="0"
              scrolling="no"
            /> */}
            <a ref={refContainer} href={fileURL} onClick={()=> console.log('clicked')}></a>
    </div>
  );
}

