import React, { useState, useEffect } from "react";
import "../styles/main.scss";
import config from "../utils/config";
import _ from "lodash";
import Footer from "./Footer";
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";
import Navigation from "./Navigation";
import apicall from "../apis/apicall";
import { withTranslation } from "react-i18next";
import helpers from "../utils/helpers";
import OverlaySpinner from "./OverlaySpinner";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";

function DetailView(props) {
  const [systemBoardMembers, setsystemBoardMembers] = useState([]);
  const [manualBoardMembers, setmanualBoardMembers] = useState([]);
  const orgNumber = props.match.params.orgNumber;
  const [company, setcompany] = useState();
  const [path, setpath] = useState();
  const [toggleNavigation, setToggleNavigation] = useState(false);
  const [toggleHelp, setToggleHelp] = useState(false);
  const [loading, setLoading] = useState(true);
  const { t } = props;
  const history = useHistory();
  const [document_type, setdocument_type] = useState();
  const [documentId, setdocumentId] = useState(null);
  const [broker, setbroker] = useState();
  const [smooth_organization, setsmooth_organization] = useState();
  const [price, setprice] = useState();
  const [couponId, setcouponId] = useState(0);
  const [discount, setdiscount] = useState(null);
  const [coupon, setcoupon] = useState(null);
  const [selectedBoardMembers, setselectedBoardMembers] = useState([]);
  const location = useLocation();
  const [person_number, setperson_number] = useState(null);

  const fetchDocumentType = async () => {
    await config.LOCAL_FORAGE.getItem("document_type").then((resp) => {
      setdocument_type(resp);
    });
  };

  const fetchCachedManualMembers = async () => {
    await config.LOCAL_FORAGE.getItem("manual-members").then((resp) => {
      setmanualBoardMembers(_.sortBy(resp, ["role"]));
    });
  };

  const fetchCachedSystemMembers = async () => {
    await config.LOCAL_FORAGE.getItem("system-members").then((resp) => {
      setsystemBoardMembers(_.sortBy(resp, ["role"]));
    });
  };

  const fetchCachedPath = async () => {
    await config.LOCAL_FORAGE.getItem("path").then((value) => {
      setpath(value);
    });
  };

  const fetchDocumentId = async () => {
    await config.LOCAL_FORAGE.getItem("documentId").then((resp) => {
      setdocumentId(resp);
    });
  };

  const fetchPersonNumber = async () => {
    await config.LOCAL_FORAGE.getItem("person_number").then((resp) => {
      setperson_number(resp);
    });
  };

  const fetchSelectedBoardMembers = async () => {
    await config.LOCAL_FORAGE.getItem("selected-members").then((resp) => {
      setselectedBoardMembers(resp);
    });
  };

  const fetchCompany = () => {
    apicall.getCompany(orgNumber,false,true).then((resp) => {
      if (resp) {
        setcompany(resp);
      }
    });
  };

  useEffect(() => {
    fetchCompany();
    fetchCachedManualMembers();
    fetchCachedSystemMembers();
    fetchCachedPath();
    fetchDocumentType();
    fetchDocumentId();
    fetchSelectedBoardMembers();
    fetchPersonNumber();
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!_.isNull(documentId)) {
      getBrokerFromSmooth();
      getOrganisationFromSmooth();
    }
  }, [documentId, person_number]);

  useEffect(() => {
    // if (validOrg) {
    // let orgnr_number = _.isObject(orgNr) ? orgNr.value : orgNr;
    apicall.getCoupon(orgNumber).then((resp) => {
      if (resp.message === "NOT_FOUND") {
        basePrices();
      } else if (!_.isEmpty(resp.data)) {
        let discount = resp.data.discount;
        setdiscount(discount);
        setcouponId(resp.data.id);
        document_type === "other_document"
          ? setprice(helpers.calculateotherDocumentPrice(null))
          : setprice(helpers.calculatePrice(null));
      }
    });
    // } else {
    //   basePrices();
    // }
  }, [orgNumber, document_type]);

  const basePrices = () => {
    setcoupon(null);
    document_type === "other_document"
      ? setprice(helpers.calculateotherDocumentPrice(null))
      : setprice(helpers.calculatePrice(null));
    setdiscount(null);
  };

  const constructTable = (boardMembers) => {
    const sortedMembers = _.sortBy(boardMembers, ["role"]);
    return sortedMembers.map((boardMember, index) => {
      return (
        <tr className="row-member" key={index}>
          {document_type !== "other_document" && (
            <td className="member-role">{boardMember.role}</td>
          )}
          <td className="member-name">{boardMember.name}</td>
          <td className="member-contactinfo">
            <div>
              {boardMember.email}, {boardMember.mobile}
            </div>
          </td>
        </tr>
      );
    });
  };

  const getBrokerFromSmooth = () => {
    if (!_.isNull(person_number))
      apicall.getBrokerFromSmooth(person_number).then((resp) => {
        if (resp) {
          setbroker(resp);
        }
      });
  };

  const getOrganisationFromSmooth = () => {
    apicall.getOrganisationFromSmooth(orgNumber).then((resp) => {
      if (resp) {
        setsmooth_organization(resp);
      }
    });
  };

  const createAgreement = () => {
    let companyObj = company;
    let cachedBoardMembers = [...systemBoardMembers, ...manualBoardMembers];
    const idArray = cachedBoardMembers.map((x) => x.id);
    let boardMembers = _.filter(company.boardMembers, (x) => {
      if (idArray.includes(x.id)) {
        let obj = x;
        const roleid = cachedBoardMembers.map((x) => x.rid);
        let filteredRoles = _.filter(obj.memberRoles, (role) => {
          if (roleid.includes(role.uuid)) return role;
        });
        obj.memberRoles = filteredRoles;
        return obj;
      }
    });
    companyObj.boardMembers = boardMembers;
    setcompany(companyObj);
    if (!_.isNull(documentId)) {
      setLoading(true); 
      let reqObj = {
        organization: {
          orgnr_number: smooth_organization.orgnr_number,
          address: smooth_organization.address,
          zipcode: smooth_organization.zipcode,
          city: smooth_organization.city,
          business: smooth_organization.business,
          email: smooth_organization.email,
          invoice_send_mode: smooth_organization.invoice_send_mode,
        },
        broker: {
          email: broker.email,
          mobile_number: broker.phone_number,
          first_name: broker.first_name,
          last_name: broker.last_name,
        },
        signator_product: {
          product_name: "Signator",
          product_code: "Signator",
          plan_type: "Regular",
          price: helpers.calculateTotal(price),
          clientname: company.name,
          apartment_number: smooth_organization.address,
          ordered_at: new Date().toISOString(),
          discount_info: discount ? discount : 0,
        },
      };
      apicall.createInvoice(reqObj, couponId).then((resp) => {
        // setsubmitDisabled(false);
        console.log("createInvoice---->", document_type);
        if (resp) {
          let invoiceId = resp.signator_invoice.id;
          apicall
            .createOtherDocsAgreement(
              companyObj,
              invoiceId,
              t,
              selectedBoardMembers,
              documentId
            )
            .then((resp) => {
              setLoading(false);
              if (resp.status < 400) {
                let id = resp.data.orderYearlyAgreements[0].agreementId;
                history.push(
                  {
                    pathname: `/summary/${orgNumber}/${id}`,
                  },
                  {
                    companyName: companyObj.name,
                  }
                );
              }
            });
        }
      });
    } else {
      setLoading(true);     
      history.push("/betala-faktura-epostadress", {
        agreementObj: companyObj,
        loginOrgNumber: orgNumber,
        companyName: company.name,
      });
    }
  };
  return (
    <div
      className={`container detailview ${
        loading ? "has-spinner has-overlay" : ""
      } ${toggleNavigation ? " has-navigation" : ""} ${
        toggleHelp ? " has-help" : ""
      }`}
    >
      <main>
        <MainHeader {...props} />
        {loading && <OverlaySpinner />}
        <div className="app">
          <h1>{t("review")}</h1>
          <div className="form-group">
            <label>{t("organizationnumber")}</label>
            <p>{orgNumber}</p>
          </div>
          <div className="form-group">
            <label>{t("associationcompany")}</label>
            <p>{company && company.name}</p>
          </div>
          <div className="form-group document">
            <label>{t("document")} 1</label>
            {/* <p className="ellipsis">{helpers.getFilename(path)}</p> */}
            {document_type === "other_document" ? (
              <p className="ellipsis">{helpers.getFilename(path)}</p>
            ) : (
              <p className="ellipsis">
                {helpers.getFilenameWithTimeStamp(path)}
              </p>
            )}
            <a
              href={helpers.encodePath(path)}
              target="_blank"
              className={`${path ? "icon icon-document-1" : ""}`}
              alt=""
              title={t("opendocument")}
            >
              {t("opendocument")}
            </a>
          </div>
          <div>
            <table className="table-members-list">
              <thead>
                <tr>
                  {document_type !== "other_document" && <th>{t("role")}</th>}
                  <th>{t("name")}</th>
                  <th>{t("contactinfo")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {systemBoardMembers && constructTable(systemBoardMembers)}
                {manualBoardMembers && constructTable(manualBoardMembers)}
              </tbody>
            </table>
          </div>
          <button
            disabled={loading ? true : false}
            className="btn btn-success"
            onClick={createAgreement}
          >
            {t("next")}
          </button>
        </div>
        <MainFooter
          setToggleNavigation={setToggleNavigation}
          toggleNavigation={toggleNavigation}
          setToggleHelp={setToggleHelp}
          toggleHelp={toggleHelp}
        />
      </main>
      <Footer
        setToggleNavigation={setToggleNavigation}
        toggleNavigation={toggleNavigation}
        setToggleHelp={setToggleHelp}
        toggleHelp={toggleHelp}
      />
      <Navigation toggleNavigation={toggleNavigation} />
    </div>
  );
}
export default withTranslation()(DetailView);
