import Axios from "axios";
import config from "../utils/config";
import { withRouter } from "react-router";
import helpers from "../utils/helpers";
import _ from "lodash";
var convert = require("xml-js");

var apicall = {
  createInvoice: async function (invoiceObj, couponId) {
    let responseData;
    const headers = await helpers.getJsonHeaders();
    const URL = `${config.BASE_URL}/invoice/createInvoice?orgNumber=${invoiceObj.organization.orgnr_number}&couponId=${couponId}&isProd=${config.isProd}`;
    await Axios.post(URL, invoiceObj, headers)
      .then((response) => {
        console.log(response);
        if (response.status === 201 || response.status === 200) {
          if (_.isEmpty(response.data.data))
            // helpers.notify(t("msg_emailalreadyexists"));
            // else helpers.notify(t("msg_accountcreated"));
            responseData = response.data;
        } else {
          helpers.notify("Payment failed");
        }
      })
      .catch((error) => {
        responseData = error.response;
        // helpers.notify(t("msg_emailalreadyexists"));
      });
    return responseData;
  },
  getCoupon: async function (orgNumber) {
    let response;
    let URL = `${config.BASE_URL}/invoice/getCoupon?orgNumber=${orgNumber}`;
    await Axios.get(URL)
      .then((resp) => {
        response = resp.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },
  getBroker: async function (email) {
    let response;
    let URL = `${config.MAKLARSERVICE_URL}broker_request?broker_email=${email}&signator=true`;
    await Axios.get(URL)
      .then((resp) => {
        response = resp.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },
  getOrg: async function (orgNumber) {
    // let orgNum = orgNumber.slice(0, 6) + "-" + orgNumber.slice(6, 10);
    let response;
    let URL = `${
      config.MAKLARSERVICE_URL
    }broker_office_request?organizationNumber=${orgNumber.trim()}`;
    await Axios.get(URL)
      .then((resp) => {
        response = resp.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },
  getCompanyFromSmooth: async function (orgNumber) {
    let result;
    let URL = `${config.SMOOTH_URL}?org_number=${orgNumber.trim()}`;
    await Axios.get(URL, {
      "Content-Type": "application/xml; charset=utf-8",
    })
      .then((resp) => {
        let xml = resp.data;
        result = convert.xml2js(xml, { compact: true, spaces: 4 });
        result = resp.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  getCompany: async function (orgNumber,updateEmails,synaUpdate) {
    let response;
    let URL = `${
      config.BASE_URL
    }company/getCompany?orgNumber=${orgNumber.replace("-", "").trim()}`;

    // const urlWithParam =updateEmails? `${URL}&updateEmails=${updateEmails}` : URL;
    let urlWithParam;
    if(updateEmails && synaUpdate)
    {
      urlWithParam=`${URL}&updateEmails=${updateEmails}&synaupdate=${synaUpdate}`
    }
    else if(updateEmails)
    {
      urlWithParam=`${URL}&updateEmails=${updateEmails}`
    }
    else if(synaUpdate)
    {
      urlWithParam=`${URL}&synaupdate=${synaUpdate}`
    }
    else{
      urlWithParam= URL
    }
    await Axios.get(urlWithParam)
      .then((resp) => {
        response = resp.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },
  getBoardMember: async function (id) {
    let response;
    let URL = `${config.BASE_URL}/boardMember/getBoardMember?id=${id}`;
    await Axios.get(URL)
      .then((resp) => {
        response = resp.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },
  createRavisor: async function (accountantsArray, t) {
    let responseData;
    const headers = await helpers.getJsonHeaders();
    const URL = `${config.BASE_URL}boardMember/createAccountants`;
    await Axios.post(URL, accountantsArray, headers)
      .then((response) => {
        console.log(response);
        if (response.status === 201 || response.status === 200) {
          if (_.isEmpty(response.data.data))
            helpers.notify(t("msg_emailalreadyexists"));
          // else helpers.notify(t("msg_accountcreated"));
          responseData = response.data;
        }
      })
      .catch((error) => {
        responseData = error.response;
        helpers.notify(t("msg_emailalreadyexists"));
      });
    return responseData;
  },
  deleteRavisor: async function (ravisorId) {
    let response;
    const URL = `${config.BASE_URL}boardMember/removeAccountant?id=${ravisorId}`;
    await Axios.delete(URL)
      .then((resp) => {
        response = resp.data;
      })
      .catch((err) => {
        response = err;
      });
    return response;
  },
  updateBoardMember: async function (id, email, mobile) {
    let resp;
    // headers
    const headers = await helpers.getHeaders();
    var formdata = new FormData();
    formdata.append("id", id);
    formdata.append("email", email);
    !_.isEmpty(mobile) && formdata.append("mobile", mobile);
    const URL = `${config.BASE_URL}boardMember/updateBoardMember`;
    await Axios.post(URL, formdata, { headers: headers })
      .then((response) => {
        console.log(response);
        resp = response.data;
      })
      .catch((error) => {
        console.log("catch", error.response);
        resp = error.response;
      });
    return resp;
  },
  createAgreement: async function (company,invoiceId, t,selectedBoardMembers) {
    let resp;
    let agreementPath = await config.LOCAL_FORAGE.getItem("path").then(
      (value) => {
        return value;
      }
    );
    let filename = helpers.getFilename(agreementPath);
    var formdata = new FormData();
    formdata.append("company", JSON.stringify(company));
    formdata.append("agreementPath", agreementPath);
    formdata.append("agreementName", filename);
    formdata.append("ipAddress", "127.0.0.0");
    formdata.append("invoiceId",invoiceId)
    formdata.append("selected-members",selectedBoardMembers)
    console.log(formdata);
    await Axios.post(`${config.BASE_URL}company/createAgreement`, formdata)
      .then((response) => {
        console.log(response);
        resp = response.data;
        // helpers.notify(t("msg_agreementcreated"));
      })
      .catch((error) => {
        console.log("catch", error.response);
        resp = error.response;
        helpers.notify(t("msg_agreementcreationfail"));
      });
    return resp;
  },
  uploadAgreement: async function (file, t) {
    let resp;
    var data = new FormData();
    if (typeof file === 'object' && file instanceof File)
   { 
    data.append("agreement", file);
  }
   else{
    data.append("document_id", file);
   }
    await Axios.post(`${config.BASE_URL}company/uploadAgreement`, data)
      .then((response) => {
        console.log(response);
        resp = response.data;
      })
      .catch((error) => {
        console.log("catch", error.response);
        helpers.notify(t("msg_uploadfail"));
      });
    return resp;
  },
  getFile: async function (filePath) {
    let response;
    let URL = `${config.BASE_URL}company/getFile?path=${filePath}&format=Base64`;
    await Axios.get(URL)
      .then((resp) => {
        response = resp.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },
  getAgreement: async function (id) {
    let response;
    let URL = `${config.BASE_URL}agreement/getAgreement/${id}`;
    await Axios.get(URL)
      .then((resp) => {
        response = resp.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },
  updateRevMember: async function (id, email, mobile, invite, t) {
    let resp;
    const headers = await helpers.getHeaders();
    var formdata = new FormData();
    formdata.append("id", id);
    formdata.append("email", email);
    formdata.append("mobile", mobile);
    formdata.append("updateBoardMember", false);
    formdata.append("reSendEmail", invite);
    const URL = `${config.BASE_URL}orderYearlyRevMembers/updateMembers`;
    await Axios.post(URL, formdata, { headers: headers })
      .then((response) => {
        console.log(response);
        resp = response.data;
      })
      .catch((error) => {
        console.log("catch", error.response);
        resp = error.response;
      });
    return resp;
  },
  getOrderYearlyRev: async function (id) {
    let response;
    let URL = `${config.BASE_URL}orderYearlyRev/getYearlyRev/${id}`;
    await Axios.get(URL)
      .then((resp) => {
        response = resp.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },
  createRevAgreement: async function (
    signedBoardAgreementPath,
    accountantAgreementPath,
    revMembers,
    ordersYearlyRevId,
    t
  ) {
    let resp;
    var formdata = new FormData();
    formdata.append("revMembers", JSON.stringify(revMembers));
    formdata.append("signedBoardAgreementPath", signedBoardAgreementPath);
    formdata.append("accountantAgreementPath", accountantAgreementPath);
    formdata.append(
      "accountantAgreementName",
      accountantAgreementPath.split("\\").pop().split("/").pop()
    );
    formdata.append("ordersYearlyRevId", ordersYearlyRevId);
    formdata.append("ipAddress", "192.0.0.1");
    console.log(formdata);
    await Axios.post(
      `${config.BASE_URL}company/createAccoutantAgreement`,
      formdata,
      t
    )
      .then((response) => {
        console.log(response);
        resp = response.data;
        if (response.data.status < 400) {
          // helpers.notify(t("msg_accountantreportcreated"));
        } else {
          helpers.notify(t("msg_accountantreportcreationfail"));
        }
      })
      .catch((error) => {
        console.log("catch", error.response);
        helpers.notify(t("msg_accountantreportcreationfail"));
      });
    return resp;
  },
  createMember: async function (accountantsArray, t) {
    let responseData;
    const headers = await helpers.getJsonHeaders();
    const URL = `${config.BASE_URL}boardMember/createMembers`;
    await Axios.post(URL, accountantsArray, headers)
      .then((response) => {
        console.log(response);
        if (response.status === 201 || response.status === 200) {
          if (_.isEmpty(response.data.data))
            helpers.notify(t("msg_emailalreadyexists"));
          // else helpers.notify(t("msg_accountcreated"));
          responseData = response.data;
        }
      })
      .catch((error) => {
        responseData = error.response;
        helpers.notify(t("msg_emailalreadyexists"));
      });
    return responseData;
  },
  createOtherDocsAgreement: async function (company,invoiceId, t,selectedBoardMembers,document_id) {
    let resp;
    let agreementPath = await config.LOCAL_FORAGE.getItem("path").then(
      (value) => {
        return value;
      }
    );
    let filename = helpers.getFilename(agreementPath);
    var formdata = new FormData();
    formdata.append("company", JSON.stringify(company));
    formdata.append("agreementPath", agreementPath);
    formdata.append("agreementName", filename);
    formdata.append("ipAddress", "127.0.0.0");
    formdata.append("invoiceId",invoiceId)
    formdata.append("selected-members",selectedBoardMembers)
    formdata.append("document_Id",document_id)
    console.log(formdata);
    await Axios.post(`${config.BASE_URL}company/createAnyAgreement`, formdata)
      .then((response) => {
        console.log(response);
        resp = response.data;
        // helpers.notify(t("msg_agreementcreated"));
      })
      .catch((error) => {
        console.log("catch", error.response);
        resp = error.response;
        helpers.notify(t("msg_agreementcreationfail"));
      });
    return resp;
  },
  
  updateNewRevMember: async function (id, email, mobile, invite, t) {
    let resp;
    const headers = await helpers.getHeaders();
    var formdata = new FormData();
    formdata.append("id", id);
    formdata.append("email", email);
    formdata.append("mobile", mobile);
    formdata.append("updateBoardMember", false);
    formdata.append("reSendEmail", invite);
    const URL = `${config.BASE_URL}orderYearlyRevMembers/newupdateMembers`;
    await Axios.post(URL, formdata, { headers: headers })
      .then((response) => {
        console.log(response);
        resp = response.data;
      })
      .catch((error) => {
        console.log("catch", error.response);
        resp = error.response;
      });
    return resp;
  },
  createNewRevAgreement: async function (
    signedBoardAgreementPath,
    accountantAgreementPath,
    revMembers,
    ordersYearlyRevId,
    t
  ) {
    let resp;
    var formdata = new FormData();
    formdata.append("revMembers", JSON.stringify(revMembers));
    formdata.append("signedBoardAgreementPath", signedBoardAgreementPath);
    formdata.append("accountantAgreementPath", accountantAgreementPath);
    formdata.append(
      "accountantAgreementName",
      accountantAgreementPath.split("\\").pop().split("/").pop()
    );
    formdata.append("ordersYearlyRevId", ordersYearlyRevId);
    formdata.append("ipAddress", "192.0.0.1");
    console.log(formdata);
    await Axios.post(
      `${config.BASE_URL}company/createNewAccoutantAgreement`,
      formdata,
      t
    )
      .then((response) => {
        console.log(response);
        resp = response.data;
        if (response.data.status < 400) {
          // helpers.notify(t("msg_accountantreportcreated"));
        } else {
          helpers.notify(t("msg_accountantreportcreationfail"));
        }
      })
      .catch((error) => {
        console.log("catch", error.response);
        helpers.notify(t("msg_accountantreportcreationfail"));
      });
    return resp;
  },
  getOrganisationFromSmooth: async function (orgNumber) {
    let result;
    const requestData = {
      orgnr:orgNumber.replace("-", "").trim()
    };
    const headers = await helpers.getJsonHeaders();
    let URL = `https://staging-smoot.cortexcraft.com/organization_invoice_details.json`;    
      await Axios.post(URL, requestData,headers)
      .then((resp) => {
        // let xml = resp.data;
        // result = convert.xml2js(xml, { compact: true, spaces: 4 });
        // result = resp.data;
        result = resp.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  },
  getBrokerFromSmooth: async function (person_number) {
    let response;
    let URL = `https://staging-smoot.cortexcraft.com/board_member_email?person_number=${person_number}`;
    await Axios.get(URL)
      .then((resp) => {
        response = resp.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },
};
export default withRouter(apicall);

