import React, { useState, useEffect } from "react";
import "../styles/main.scss";
import Header from "./Header";
import MainHeader from "./MainHeader";
import Footer from "./Footer";
import Navigation from "./Navigation";
import apicall from "../apis/apicall";
import config from "../utils/config";
import _ from "lodash";
import helpers from "../utils/helpers";
import OverlaySpinner from "./OverlaySpinner";
import Spinner from "./Spinner";
import { withTranslation } from "react-i18next";

function DeleteBoardMember(props) {
  const [bortRevisor, setbortRevisor] = useState(false);
  const { t } = props;
  const [toggleNavigation, setToggleNavigation] = useState(false);
  const [toggleHelp, setToggleHelp] = useState(false);
  const [formArray, setformArray] = useState([]);
  const [loading, setLoading] = useState(false);

  const memberId = parseInt(props.match.params.memberId);

  const fetchCachedManualMembers = async () => {
    await config.LOCAL_FORAGE.getItem("manual-members").then((resp) => {
      setformArray(resp);
    });
  };

  useEffect(() => {
    fetchCachedManualMembers();
  }, []);

  const handleClick = () => {
    setLoading(false);
    props.history.push(`/deleteAccountant/${memberId}`);
  };

  const renderForm = () => {
    return formArray.map((form, index) => {
      return form.id == memberId ? (
        <div className="user-form" key={`form${index}`}>
          <div className="form-group">
            <label>{t("role")}</label>
            <select
              className="icon-chevron-down"
              value={form.role}
              name="role"
              id={`role${form.id}`}
              data-id={form.id}
              disabled={true}
            >
              <option disabled>{t("selectrole")}</option>
              <option value="REVEX">{t("externalauditor")}</option>
              <option value="REVIN">{t("internalauditor")}</option>
            </select>
          </div>
          <div className="form-group">
            <label for="firstName">{t("firstname")}</label>
            <input
              type="text"
              id={`firstName${form.id}`}
              data-id={form.id}
              name="firstName"
              value={form.firstName}
              disabled={true}
            />
          </div>
          <div className="form-group">
            <label for="lastName">{t("lastname")}</label>
            <input
              type="text"
              name="lastName"
              id={`lastName${form.id}`}
              data-id={form.id}
              value={form.lastName}
              disabled={true}
            />
          </div>
          <div className="form-group">
            <label for="email">{t("emailaddress")}</label>
            <input
              type="email"
              id={`email${form.id}`}
              data-id={form.id}
              name="email"
              value={form.email}
              disabled={true}
            />
          </div>
          <div className="form-group">
            <label for="mobile">{t("mobilenumber")} ({t("optional")})</label>
            <input
              type="tel"
              id={`mobile${form.id}`}
              data-id={form.id}
              name="mobile"
              value={form.mobile}
              maxLength={10}
              disabled={true}
            />
          </div>
        </div>
      ) : (
          <></>
        );
    });
  };

  return (
    <div
      className={`container createuser ${
        toggleNavigation ? " has-navigation" : ""
        } ${toggleHelp ? " has-help" : ""}`}
    >
      <main>
        <MainHeader {...props} />
        <div className="app">
          <p className="button-group__centered">Revisor</p>
          <form>
            {renderForm()}
            {loading && <OverlaySpinner />}
            <label>
              <input
                type="checkbox"
                checked={bortRevisor}
                onChange={(e) => setbortRevisor(e.target.checked)}
              ></input>{" "}
            Ta bort revisor
          </label>
            <p className="button-group__centered">
              <button
                className={`btn ${bortRevisor ? "btn-primary" : ""} `}
                disabled={bortRevisor ? false : true}
                onClick={() => handleClick()}
              >
                {t("save")}
              </button>
            </p>
          </form>
        </div>
      </main>
      <Navigation toggleNavigation={toggleNavigation} />
      <Footer
        setToggleNavigation={setToggleNavigation}
        toggleNavigation={toggleNavigation}
        setToggleHelp={setToggleHelp}
        toggleHelp={toggleHelp}
      />
      <OverlaySpinner />
    </div>
  );
}

export default withTranslation()(DeleteBoardMember);
