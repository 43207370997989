import React, { useState, useEffect } from "react";
import "../styles/main.scss";
import Header from "./Header";
import Footer from "./Footer";
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";
import Navigation from "./Navigation";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import helpers from "../utils/helpers";
import config from "../utils/config";
import CreatableSelect from "react-select/creatable";
import apicall from "../apis/apicall";
import OverlaySpinner from "./OverlaySpinner";
import Spinner from "./Spinner";
function PaymentInvoice(props) {
  // console.log(props);
  const { t } = props;
  const [toggleNavigation, setToggleNavigation] = useState(false);
  const [toggleHelp, setToggleHelp] = useState(false);
  let history = useHistory();
  const location = useLocation();
  const [brokerObj, setBrokerObj] = useState();
  const [orgNr, setorgNr] = useState("");
  const [company, setcompany] = useState(null);
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [billingAddr, setbillingAddr] = useState("");
  const [zip, setzip] = useState(null);
  const [city, setcity] = useState("");
  const [emailInvoice, setemailInvoice] = useState(false);
  const [email, setemail] = useState("");
  const [confirmEmail, setconfirmEmail] = useState("");
  const [errors, seterrors] = useState({});
  const [brokerExist, setbrokerExist] = useState(false);
  const [orgOptions, setorgOptions] = useState([]);
  const [invoiceEmail, setinvoiceEmail] = useState("");
  const [mobile, setmobile] = useState();
  const [loading, setLoading] = useState(false);
  const [coupon, setcoupon] = useState(null);
  const [validOrg, setvalidOrg] = useState(false);
  const [accepetAgreement, setaccepetAgreement] = useState(false);
  const [price, setprice] = useState();
  const [postalCharge, setpostalCharge] = useState(config.postalCharges);
  const [vat, setvat] = useState();
  const [couponId, setcouponId] = useState(0);
  const [submitDisabled, setsubmitDisabled] = useState(false);
  const [loaderMSg, setloaderMSg] = useState(t("loading"));
  const [discount, setdiscount] = useState(null);
  const [selectedBoardMembers, setselectedBoardMembers] = useState([]);
  const [document_type, setdocument_type] = useState();
  const [documentId, setdocumentId] = useState(null);

  useEffect(() => {
    if (!_.isEmpty(location.state.broker)) {
      let historyState = location.state;
      console.log("historyState", historyState);

      let broker = historyState.broker;
      setbrokerExist(true);
      setBrokerObj(broker);
      setfirstName(broker.first_name);
      setlastName(broker.last_name);
      setemail(historyState.userEmail);
      setmobile(broker.mobile_number);
      let broker_offices = broker.broker_all_organizations;
      if (!_.isEmpty(broker_offices)) {
        let org_options = helpers.createOptions(broker_offices);
        setorgOptions(org_options);
        setDefaultOrg(org_options[0]);
      }
    } else {
      setemail(location.state.userEmail);
    }
  }, []);

  const fetchSelectedBoardMembers = async () => {
    await config.LOCAL_FORAGE.getItem("selected-members").then((resp) => {
      setselectedBoardMembers(resp);
    });
  };

  const fetchDocumentType = async () => {
    await config.LOCAL_FORAGE.getItem("document_type").then((resp) => {
      console.log("document_type====>", resp);
      setdocument_type(resp);
    });
  };

  const fetchDocumentId = async () => {
    await config.LOCAL_FORAGE.getItem("documentId").then((resp) => {
      setdocumentId(resp);
    });
  };


  useEffect(() => {
    fetchSelectedBoardMembers();
    fetchDocumentType();
    fetchDocumentId();
  }, []);

  const basePrices = () => {    
    setcoupon(null);
    document_type === "other_document"
      ? setprice(helpers.calculateotherDocumentPrice(null))
      : setprice(helpers.calculatePrice(null));
    setdiscount(null);
  };

  useEffect(() => {
    if (validOrg) {
      let orgnr_number = _.isObject(orgNr) ? orgNr.value : orgNr;
      apicall.getCoupon(orgnr_number).then((resp) => {
        if (resp.message === "NOT_FOUND") {
          basePrices();
        } else if (!_.isEmpty(resp.data)) {
          let discount = resp.data.discount;
          setdiscount(discount);
          setcouponId(resp.data.id);
          document_type === "other_document"
            ? setprice(helpers.calculateotherDocumentPrice(null))
            : setprice(helpers.calculatePrice(null));
        }
      });
    } else {
      basePrices();
    }
  }, [validOrg, orgNr,document_type]);

  const getCompany = (org) => {
    // apicall.getCompanyFromSmooth("5592106925").then((resp) => {
    //   setLoading(false);
    //   if (resp) {
    //     if (_.isEmpty(resp)) {
    //     } else {
    //       console.log(resp);
    //       let data = resp.Svar.Objektlista.Objekt.Omfragad;
    //       setcompany(data.Namnlista.Namn._text);
    //       let addrObj = data.Adresslista.Adress;
    //       setbillingAddr(addrObj.Gatabox._text)
    //       let org = resp.organization;
    //       setbillingAddr(org.address);
    //       setzip(org.zipcode);
    //       setcity(org.city);
    //       setemailInvoice(org.invoice_send_mode == "email" ? true : false);
    //     }
    //   }
    // });
    if (org.length == 10 && helpers.isNumString(org)) {
      setloaderMSg(t("fetchinCompany"));
      setLoading(true);
      apicall.getCompany(org).then((resp) => {
        setLoading(false);
        if (_.isEmpty(resp)) {
          setvalidOrg(false);
          resetOrgDetails();
          helpers.notify(t("orgInvalid"));
        } else {
          let addr = resp.addresses[0];
          if (_.isEmpty(resp.name)) {
            helpers.notify(t("orgInvalid"));
            resetOrgDetails();
            setvalidOrg(false);
            return;
          }
          setbillingAddr(addr.streetAddress);
          setcity(addr.city);
          setzip(addr.postCode);
          setcompany(resp.name);
          setvalidOrg(true);
        }
      });
    }
  };

  const setDefaultOrg = (value) => {
    setvalidOrg(true);
    setorgNr(value);
    setcompany(value.org.business);
    setbillingAddr(value.org.address);
    setzip(value.org.zipcode);
    setcity(value.org.city);
    setemailInvoice(value.org.invoice_send_mode == "email" ? true : false);
    setinvoiceEmail(value.org.email);
  };

  const validateForm = () => {
    let isValid = true;
    let errors = {};
    if (helpers.isEmpty(email)) {
      isValid = false;
      errors["email"] = t("enterEmail");
    }
    if (!_.isUndefined(email)) {
      var pattern = config.email_pattern;
      if (!pattern.test(email) && email !== "") {
        isValid = false;
        errors["email"] = t("invalidEmail");
      }
    }
    if (!brokerExist && helpers.isEmpty(confirmEmail)) {
      isValid = false;
      errors["confirmEmail"] = t("enterConfirmEmail");
    }
    if (
      !brokerExist &&
      !helpers.isEmpty(confirmEmail) &&
      email.trim() !== confirmEmail.trim()
    ) {
      isValid = false;
      errors["confirmEmail"] = t("invalidConfEmail");
    }
    if (emailInvoice) {
      if (helpers.isEmpty(invoiceEmail)) {
        isValid = false;
        errors["invoiceEmail"] = t("enterInvEmail");
      }
      if (!_.isUndefined(invoiceEmail)) {
        var pattern = config.email_pattern;
        if (!pattern.test(invoiceEmail) && invoiceEmail !== "") {
          isValid = false;
          errors["invoiceEmail"] = t("invalidEmail");
        }
      }
    }
    if (!brokerExist && helpers.isEmpty(orgNr)) {
      isValid = false;
      errors["orgNr"] = t("enterOrgNr");
    }
    if (!brokerExist && !helpers.isEmpty(orgNr) && orgNr.length != 10) {
      isValid = false;
      errors["orgNr"] = t("orgInvalid");
    }
    if (helpers.isEmpty(company)) {
      isValid = false;
      errors["company"] = t("enterCompany");
    }
    if (helpers.isEmpty(firstName)) {
      isValid = false;
      errors["firstName"] = t("enterFname");
    }
    if (helpers.isEmpty(lastName)) {
      isValid = false;
      errors["lastName"] = t("enterLname");
    }
    if (helpers.isEmpty(billingAddr)) {
      isValid = false;
      errors["billingAddr"] = t("enterAddr");
    }
    if (helpers.isEmpty(city)) {
      isValid = false;
      errors["city"] = t("enterCity");
    }
    if (helpers.isEmpty(zip)) {
      isValid = false;
      errors["zip"] = t("enterZip");
    }
    seterrors(errors);
    return isValid;
  };

  const isValidNewOption = (inputValue, selectValue, selectOptions) => {
    if (inputValue.length == 10 && helpers.isNumString(inputValue)) {
      return {
        label: inputValue,
        value: inputValue,
      };
    }
  };

  const resetOrgDetails = () => {
    setcompany("");
    setbillingAddr("");
    setzip("");
    setcity("");
  };

  const getOrg = (orgNum) => {
    setLoading(true);
    apicall.getOrg(orgNum).then((resp) => {
      setLoading(false);
      if (_.isEmpty(resp)) {
        getCompany(orgNum);
      } else {
        let org = _.has(resp, "organization") ? resp.organization : resp;
        setcompany(org.business);
        setbillingAddr(org.address);
        setzip(org.zipcode);
        setcity(org.city);
        setemailInvoice(org.invoice_send_mode == "email" ? true : false);
        setvalidOrg(true);
      }
    });
  };

  const handleOrgChange = (value) => {
    if (_.isEmpty(value)) {
      setorgNr("");
      setcompany("");
      setbillingAddr("");
      setzip("");
      setcity("");
      setvalidOrg(false);
      setinvoiceEmail("");
    } else if (!_.has(value, "__isNew__")) {
      setorgNr(value);
      setcompany(value.org.business);
      setbillingAddr(value.org.address);
      setzip(value.org.zipcode);
      setcity(value.org.city);
      setemailInvoice(value.org.invoice_send_mode == "email" ? true : false);
      setvalidOrg(true);
      setinvoiceEmail(value.org.email);
    } else if (_.has(value, "__isNew__") && value.__isNew__) {
      setorgNr(value);
      getOrg(value.value);
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setsubmitDisabled(true);
      setLoading(true);
      let reqObj = {
        organization: {
          orgnr_number: _.isObject(orgNr) ? orgNr.value : orgNr,
          address: billingAddr,
          zipcode: zip,
          city: city,
          business: company,
          email: invoiceEmail,
          invoice_send_mode: emailInvoice ? "email" : "epost",
        },
        broker: {
          email: email,
          mobile_number: mobile,
          first_name: firstName,
          last_name: lastName,
        },
        signator_product: {
          product_name: "Signator",
          product_code: "Signator",
          plan_type: "Regular",
          // price: emailInvoice
          //   ? helpers.calculateTotal(price)
          //   : helpers.calculateTotal(postalCharge + price),
          price:helpers.calculateTotal(price),
          clientname: company,
          apartment_number: billingAddr,
          ordered_at: new Date().toISOString(),
          discount_info: discount ? discount : 0,
        },
      };

      apicall.createInvoice(reqObj, couponId).then((resp) => {
        setsubmitDisabled(false);
        console.log("createInvoice---->", document_type);
        if (resp) {
          let invoiceId = resp.signator_invoice.id;
          if (document_type === "yearly_revenue") {
            apicall
              .createAgreement(
                location.state.agreementObj,
                invoiceId,
                t,
                selectedBoardMembers
              )
              .then((resp) => {
                setLoading(false);
                if (resp.status < 400) {
                  let id = resp.data.orderYearlyAgreements[0].agreementId;
                  history.push(
                    {
                      pathname: `/summary/${location.state.loginOrgNumber}/${id}`,
                    },
                    {
                      companyName: location.state.agreementObj.name,
                    }
                  );
                }
              });
          } else if (document_type === "other_document") {
            apicall
              .createOtherDocsAgreement(
                location.state.agreementObj,
                invoiceId,
                t,
                selectedBoardMembers,
                documentId
              )
              .then((resp) => {
                setLoading(false);
                if (resp.status < 400) {
                  let id = resp.data.orderYearlyAgreements[0].agreementId;
                  history.push(
                    {
                      pathname: `/summary/${location.state.loginOrgNumber}/${id}`,
                    },
                    {
                      companyName: location.state.agreementObj.name,
                    }
                  );
                }
              });
          }
        }
      });
    }
  };

  const styles = {
    control: (base) => ({
      ...base,
      fontSize: "12px",
    }),
    menu: (base) => ({
      ...base,
      fontSize: "12px",
    }),
  };

  const getOrgForNewUser = (val) => {
    const re = /^[0-9\b]+$/;
    // if value is not blank, then test the regex
    if (val == "" || re.test(val)) {
      setorgNr(val);
    }
    if (val.length == 10 && helpers.isNumString(val)) {
      setTimeout(() => {
        getCompany(val);
      }, 200);
    }
  };

  const createSlectInput = (val) => {
    const re = /^[0-9\b]+$/;
    if (re.test(val) && val.length <= 10) {
      return val;
    } else if (helpers.isNumString(val)) {
      return val.substr(0, 10);
    }
  };

  return (
    <div
      className={`container payment-invoice ${
        toggleNavigation ? " has-navigation" : ""
      } ${toggleHelp ? " has-help" : ""}`}
    >
      {/* <Alert /> */}
      <main className={loading ? "has-spinner has-overlay" : ""}>
        <MainHeader {...props} />
        {loading ? (
          <OverlaySpinner
            msg={submitDisabled ? t("transactionMsg") : loaderMSg}
          />
        ) : (
          <div className="app">
            <h1>{t("pay")}</h1>
            <form>
              <div className="payment-form">
                <div className="form-group">
                  <h2>{t("invoice")}</h2>
                </div>
                <div className="form-group">
                  <h2>{t("invoicingDetails")}</h2>
                </div>
                <div className="form-group">
                  <label for="firstName">{t("firstname")}</label>
                  <input
                    type="text"
                    id={`firstName`}
                    name="firstName"
                    value={firstName}
                    onChange={(e) => setfirstName(e.target.value)}
                    required
                  />
                  <button
                    className="btn-clear"
                    type="reset"
                    title={t("clearfield")}
                    onClick={() => setfirstName("")}
                  >
                    {t("clearfield")}
                  </button>
                  <div className="error">{errors.firstName}</div>
                </div>
                <div className="form-group">
                  <label for="lastName">{t("lastname")}</label>
                  <input
                    type="text"
                    name="lastName"
                    id={`lastName`}
                    value={lastName}
                    onChange={(e) => setlastName(e.target.value)}
                    required
                  />
                  <button
                    className="btn-clear"
                    type="reset"
                    title={t("clearfield")}
                    onClick={() => setlastName("")}
                  >
                    {t("clearfield")}
                  </button>
                  <div className="error">{errors.lastName}</div>
                </div>
                <div className="form-group">
                  <label for="email">{t("emailaddress")}</label>
                  <input
                    type="text"
                    name="email"
                    id={`email`}
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                    required
                  />
                  <button
                    className="btn-clear"
                    type="reset"
                    title={t("clearfield")}
                    onClick={() => setemail("")}
                  >
                    {t("clearfield")}
                  </button>
                  <div className="error">{errors.email}</div>
                </div>
                {!brokerExist && (
                  <div className="form-group">
                    <label for="confirmEmail">{t("confirmEmail")}</label>
                    <input
                      type="text"
                      name="confirmEmail"
                      id={`confirmEmail`}
                      value={confirmEmail}
                      onChange={(e) => setconfirmEmail(e.target.value)}
                      required
                    />
                    <button
                      className="btn-clear"
                      type="reset"
                      title={t("clearfield")}
                      onClick={() => setconfirmEmail("")}
                    >
                      {t("clearfield")}
                    </button>
                    <div className="error">{errors.confirmEmail}</div>
                  </div>
                )}
                <div className="form-group">
                  <label for="mobile">{t("mobilenumber")}</label>
                  <input
                    type="text"
                    name="mobile"
                    id={`mobile`}
                    value={mobile}
                    onChange={(e) => setmobile(e.target.value)}
                    required
                  />
                  <button
                    className="btn-clear"
                    type="reset"
                    title={t("clearfield")}
                    onClick={() => setmobile("")}
                  >
                    {t("clearfield")}
                  </button>
                  {/* <div className="error">{errors.mobile}</div> */}
                </div>
                <div className="form-group">
                  <a name="org-details"></a>
                  <h2>{t("orgDetails")}</h2>
                </div>
                {brokerExist && (
                  <div className="form-group__">
                    <label for="orgNr">{t("orgnr")}</label>
                    <CreatableSelect
                      isClearable
                      onChange={handleOrgChange}
                      // onInputChange={this.handleInputChange}
                      options={orgOptions}
                      // styles={customStyles}
                      className="basic-single"
                      classNamePrefix="select"
                      isValidNewOption={isValidNewOption}
                      value={orgNr}
                      styles={styles}
                      onInputChange={(inputValue) =>
                        createSlectInput(inputValue)
                      }
                    />
                  </div>
                )}
                {!brokerExist && (
                  <div className="form-group">
                    <label for="orgNr">{t("orgnr")}</label>
                    <input
                      type="text"
                      id={`orgNr`}
                      name="orgNr"
                      required
                      value={orgNr}
                      // onChange={(e) => setorgNr(e.target.value)}
                      onChange={(e) => getOrgForNewUser(e.target.value)}
                      // onBlur={() => getCompany(orgNr)}
                      maxlength="10"
                    />
                    <button
                      className="btn-clear"
                      type="reset"
                      title={t("clearfield")}
                      onClick={() => {
                        setorgNr("");
                        setbillingAddr("");
                        setzip("");
                        setcity("");
                        setcompany("");
                        setvalidOrg(false);
                      }}
                    >
                      {t("clearfield")}
                    </button>
                    <div className="error">{errors.orgNr}</div>
                  </div>
                )}
                {validOrg && (
                  <>
                    <div className="form-group">
                      <label for="company">{t("associationcompany")}</label>
                      <input
                        type="text"
                        name="company"
                        id={`company`}
                        value={company}
                        onChange={(e) => setcompany(e.target.value)}
                        required
                      />
                      <button
                        className="btn-clear"
                        type="reset"
                        title={t("clearfield")}
                        onClick={() => setcompany(null)}
                      >
                        {t("clearfield")}
                      </button>
                      <div className="error">{errors.company}</div>
                    </div>
                    <div className="form-group">
                      <label for="billingAddress">{t("billingaddress")}</label>
                      <input
                        type="text"
                        name="billingAddress"
                        id={`billingAddress`}
                        value={billingAddr}
                        onChange={(e) => setbillingAddr(e.target.value)}
                        required
                      />
                      <button
                        className="btn-clear"
                        type="reset"
                        title={t("clearfield")}
                        onClick={() => setbillingAddr(null)}
                      >
                        {t("clearfield")}
                      </button>
                      <div className="error">{errors.billingAddr}</div>
                    </div>
                    <div className="form-group">
                      <label for="zipCode">{t("zipcode")}</label>
                      <input
                        type="text"
                        name="zipCode"
                        id={`zipCode`}
                        value={zip}
                        onChange={(e) => setzip(e.target.value)}
                        required
                        maxLength="5"
                      />
                      <button
                        className="btn-clear"
                        type="reset"
                        title={t("clearfield")}
                        onClick={() => setzip(null)}
                      >
                        {t("clearfield")}
                      </button>
                      <div className="error">{errors.zip}</div>
                    </div>

                    <div className="form-group">
                      <label for="city">{t("city")}</label>
                      <input
                        type="text"
                        name="city"
                        id={`city`}
                        value={city}
                        onChange={(e) => setcity(e.target.value)}
                        required
                      />
                      <button
                        className="btn-clear"
                        type="reset"
                        title={t("clearfield")}
                        onClick={() => setcity("")}
                      >
                        {t("clearfield")}
                      </button>
                      <div className="error">{errors.city}</div>
                    </div>

                    {/* <div className="form-group">
                <label for="company">{t("coupon")}</label>
                <input
                  type="text"
                  name="coupon"
                  id={`coupon`}
                  value={coupon}
                  onChange={(e) => setcoupon(e.target.value)}
                  required
                />
                <button
                  className="btn-clear"
                  type="reset"
                  title={t("clearfield")}
                  onClick={() => setcoupon(null)}
                >
                  {t("clearfield")}
                </button>
              </div>  */}

                    <div className="form-group form-check">
                      <label>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="email-invoice"
                          onChange={(e) => setemailInvoice(!emailInvoice)}
                          checked={emailInvoice}
                        ></input>
                        {t("emailinvoice")} (0 kr)
                      </label>
                    </div>

                    <div className="form-group">
                      <label for="invoiceEmail">{t("invoiceEmail")}</label>
                      <input
                        type="email"
                        name="invoiceEmail"
                        id={`invoiceEmail`}
                        value={invoiceEmail}
                        onChange={(e) => setinvoiceEmail(e.target.value)}
                        required
                      />
                      <button
                        className="btn-clear"
                        type="reset"
                        title={t("clearfield")}
                        onClick={() => setinvoiceEmail("")}
                      >
                        {t("clearfield")}
                      </button>
                      <div className="error">{errors.invoiceEmail}</div>
                    </div>
                  </>
                )}

                <div className="payment-summary">
                  {document_type === "other_document" ? (
                    <p>
                      {t("otherDocument_annualReport")}
                      {discount && ` (${discount}% ${t("discount")})`}{" "}
                      <span>{price} kr</span>
                    </p>
                  ) : (
                    <p>
                      {t("annualReport")}
                      {discount && ` (${discount}% ${t("discount")})`}{" "}
                      <span>{price} kr</span>
                    </p>
                  )}
                  {!emailInvoice && (
                    <p>
                      {t("paperInvoice")} <span>{postalCharge} kr</span>
                    </p>
                  )}
                  <p>
                    {t("vat")}{" "}
                    <span>
                      {emailInvoice
                        ? helpers.calculateVAT(price)
                        : helpers.calculateVAT(postalCharge + price)}{" "}
                      kr
                    </span>
                  </p>
                  <p className="total">
                    TOTAL{" "}
                    <span>
                      {emailInvoice
                        ? helpers.calculateTotal(price)
                        : helpers.calculateTotal(postalCharge + price)}{" "}
                      kr
                    </span>
                  </p>
                </div>
                <div className="form-check payment-terms-agreement">
                  <label className="">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="email-invoice"
                      onChange={() => setaccepetAgreement(!accepetAgreement)}
                    ></input>
                    {t("agreement")}
                  </label>
                </div>

                <div className="button-group__centered">
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={!accepetAgreement || submitDisabled || !validOrg}
                    onClick={handleSubmit}
                  >
                    {t("pay")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
        <MainFooter
          setToggleNavigation={setToggleNavigation}
          toggleNavigation={toggleNavigation}
          setToggleHelp={setToggleHelp}
          toggleHelp={toggleHelp}
        />
      </main>
      <Navigation toggleNavigation={toggleNavigation} />
      <Footer
        setToggleNavigation={setToggleNavigation}
        toggleNavigation={toggleNavigation}
        setToggleHelp={setToggleHelp}
        toggleHelp={toggleHelp}
      />
    </div>
  );
}
export default withTranslation()(PaymentInvoice);
