import React, { useState, useEffect } from "react";
import "../styles/main.scss";
import Footer from "./Footer";
import Navigation from "./Navigation";
import apicall from "../apis/apicall";
import config from "../utils/config";
import _ from "lodash";
import OverlaySpinner from "./OverlaySpinner";
import helpers from "../utils/helpers";
import { withTranslation } from "react-i18next";

function UpdateMember(props) {
  const [toggleNavigation, setToggleNavigation] = useState(false);
  const [toggleHelp, setToggleHelp] = useState(false);
  const orgNumber = !_.isEmpty(props.match.params)
    ? props.match.params.orgNumber
    : "";
  const [loading, setLoading] = useState(true);
  const [systemBoardMembers, setsystemBoardMembers] = useState([]);
  const [manualBoardMembers, setmanualBoardMembers] = useState([]);
  const { t } = props;
  const [selectedBoardMembers, setselectedBoardMembers] = useState([]);
  const [document_type, setdocument_type] = useState();
  const [documentId, setdocumentId] = useState(null);
  const [person_number, setperson_number] = useState(null);

  const [showEmail ,setshowEmail] = useState(false);

  const [showPhone ,setshowPhone] = useState(false);


  const fetchCachedManualMembers = async () => {
    await config.LOCAL_FORAGE.getItem("manual-members").then((resp) => {
      setmanualBoardMembers(resp);
    });
  };

  const fetchCachedSystemMembers = async () => {
    await config.LOCAL_FORAGE.getItem("system-members").then((resp) => {
      setsystemBoardMembers(resp);
    });
  };

  const fetchCachedSelectedBoardMembers = async () => {
    await config.LOCAL_FORAGE.getItem("selected-members").then((resp) => {
      setselectedBoardMembers(resp);
    });
  };
  const fetchWorkFlow = async () => {
    await config.LOCAL_FORAGE.getItem("document_type").then((resp) => {
      setdocument_type(resp);
    });
  };

  const fetchDocumentId = async () => {
    await config.LOCAL_FORAGE.getItem("documentId").then((resp) => {
      setdocumentId(resp);
      if(!_.isNull(resp)){
        setshowEmail(true)
        setshowPhone(true)
      }
    });
    await config.LOCAL_FORAGE.getItem("person_number").then((resp) => {
      setperson_number(resp);
    });
  };

  useEffect(() => {
    fetchCachedManualMembers();
    fetchCachedSystemMembers();
    fetchCachedSelectedBoardMembers();
    fetchWorkFlow();
    fetchDocumentId();
    setLoading(false);
  }, []);

  const handleOnBlur = (e, id, index, type) => {
    console.log("handleOnBlur called", e.target);
    let arr = [];
    if (type === "system") {
      if (document_type === "other_document") {
        arr = [
          ...systemBoardMembers.filter((item) =>
            selectedBoardMembers.includes(item.id.toString())
          ),
        ];
      } else arr = [...systemBoardMembers];
    } else {
      if (document_type === "other_document") {
        arr = [
          ...manualBoardMembers.filter((item) =>
            selectedBoardMembers.includes(item.id.toString())
          ),
        ];
      } else arr = [...manualBoardMembers];
    }
    console.log("========>", arr);
    // arr = type === "system" ?
    // [...systemBoardMembers.filter(item => selectedBoardMembers.includes(item.id.toString()))] :
    //  [...manualBoardMembers.filter(item => selectedBoardMembers.includes(item.id.toString()))];
    if (e.target.id === "email") {
      if (e.target.value && helpers.validateEmail(e.target.value)) {
        apicall.updateBoardMember(id, e.target.value, null).then((resp) => {
          // console.log(resp)
          if (resp.status === 202) {
            arr[index]["email"] = resp.data.email;
            // helpers.notify(t('email_update_success'));
          } else {
            helpers.notify(t("email_update_fail"));
          }
        });
      }
    } else if (e.target.id === "mobile") {
      const isValid = e.target.value.length > 8 ? true : false;
      if (isValid) {
        apicall
          .updateBoardMember(id, arr[index]["email"], e.target.value)
          .then((resp) => {
            // console.log(resp)
            if (resp.status === 202) {
              arr[index]["mobile"] = resp.data.mobile;
              // helpers.notify(t('mobile_update_success'));
            } else {
              helpers.notify(t("mobile_update_fail"));
            }
          });
      }
    }
    console.log("new", arr);
    if (type === "system") {
      setsystemBoardMembers(arr);
      config.LOCAL_FORAGE.setItem("system-members", arr, (err) =>
        console.log(err)
      );
    } else {
      setmanualBoardMembers(arr);
      config.LOCAL_FORAGE.setItem("manual-members", arr, (err) =>
        console.log(err)
      );
    }
  };

  const handleMailChange = (e, id, index, type) => {
    setshowEmail(true)
    let arr = [];
    if (type === "system") {
      arr =
        document_type === "other_document"
          ? [
              ...systemBoardMembers.filter((item) =>
                selectedBoardMembers.includes(item.id.toString())
              ),
            ]
          : [...systemBoardMembers];
      arr[index]["email"] = e.target.value;
      setsystemBoardMembers(arr);
    } else {
      arr =
        document_type === "other_document"
          ? [
              ...manualBoardMembers.filter((item) =>
                selectedBoardMembers.includes(item.id.toString())
              ),
            ]
          : [...manualBoardMembers];
      arr[index]["email"] = e.target.value;
      setmanualBoardMembers(arr);
    }
  };

  const handleMobileChange = (e, id, index, type) => {
    setshowPhone(true)

    let arr = [];
    if (type === "system" && !isNaN(e.target.value)) {
      arr =
        document_type === "other_document"
          ? [
              ...systemBoardMembers.filter((item) =>
                selectedBoardMembers.includes(item.id.toString())
              ),
            ]
          : [...systemBoardMembers];
      arr[index]["mobile"] = e.target.value;
      setsystemBoardMembers(arr);
    } else if (!isNaN(e.target.value)) {
      arr =
        document_type === "other_document"
          ? [
              ...manualBoardMembers.filter((item) =>
                selectedBoardMembers.includes(item.id.toString())
              ),
            ]
          : [...manualBoardMembers];
      arr[index]["mobile"] = e.target.value;
      setmanualBoardMembers(arr);
    }
  };

  const clearEmail = (e, id, index, type) => {
    let arr = [];
    if (type === "system") {
      arr =
        document_type === "other_document"
          ? [
              ...systemBoardMembers.filter((item) =>
                selectedBoardMembers.includes(item.id.toString())
              ),
            ]
          : [...systemBoardMembers];
      arr[index]["email"] = "";
      setsystemBoardMembers(arr);
    } else {
      arr =
        document_type === "other_document"
          ? [
              ...manualBoardMembers.filter((item) =>
                selectedBoardMembers.includes(item.id.toString())
              ),
            ]
          : [...manualBoardMembers];
      arr[index]["email"] = "";
      setmanualBoardMembers(arr);
    }
  };

  const handleDataSettingEvent = () => {
    let systemarr = [];
    if (document_type === "other_document") {
      if (!_.isNull(systemBoardMembers)) {
        systemarr = [
          ...systemBoardMembers.filter((item) =>
            selectedBoardMembers.includes(item.id.toString())
          ),
        ];
      }
    } else systemarr = [...systemBoardMembers];

    let manualarr = [];
    if (document_type === "other_document") {
      if (!_.isNull(manualBoardMembers)) {
        manualarr = [
          ...manualBoardMembers.filter((item) =>
            selectedBoardMembers.includes(item.id.toString())
          ),
        ];
      }
    } else manualarr = !_.isEmpty(manualBoardMembers)?[...manualBoardMembers]:null;
    setsystemBoardMembers(systemarr);
    config.LOCAL_FORAGE.setItem("system-members", systemarr, (err) =>
      console.log(err)
    );

    setmanualBoardMembers(manualarr);
    config.LOCAL_FORAGE.setItem("manual-members", manualarr, (err) =>
      console.log(err)
    );
  };

  const handleNextEvent = () => {
    handleDataSettingEvent();
    setLoading(true);
    if (!emailUpdated()) {
      if (!_.isNull(documentId)) {
        apicall.uploadAgreement(documentId, t).then((resp) => {
          console.log(resp);

          if (resp.status == 200) {
            config.LOCAL_FORAGE.setItem("path", resp.data, (err) =>
              console.log(err)
            );
            // helpers.notify(t("msg_documentuploaded"));
            props.history.push(`/detail/${orgNumber}`);
          }
          setLoading(false);
        });
      } else {
        props.history.push(`/upload/${orgNumber}`);
      }
    }
  };

  const renderForm = (type) => {
    let list;
    let viewEmail = false
    if(!showEmail){
      if (type === "manual"){
        viewEmail = true
      }
    }else{
      viewEmail = true
    }
    if (type === "system") {
      // list = systemBoardMembers;
      list =
        document_type === "other_document"
          ? systemBoardMembers.filter((item) =>
              selectedBoardMembers.includes(item.id.toString())
            )
          : systemBoardMembers;
    } else {
      // list = manualBoardMembers;
      list =
        document_type === "other_document"
          ? manualBoardMembers.filter((item) =>
              selectedBoardMembers.includes(item.id.toString())
            )
          : manualBoardMembers;
    }
    return list.map((member, index) => {
      console.log("member", member.email);
      return (
        <div className="user-form" key={`form`}>
          {/* <div className="form-group">
            <label>{t("role")}</label>
            <p>{member.role}</p>
          </div> */}
          <div className="form-group">
            <label htmlFor="fname">{t("firstname")}</label>
            <p>{member.name.split(",")[0]}</p>
          </div>
          <div className="form-group">
            <label htmlFor="lname">{t("lastname")}</label>
            <p>{member.name.split(",")[1]}</p>
          </div>
          <div className="form-group">
            <label htmlFor="email">{t("emailaddress")}</label>
            <input
              type="email"
              id="email"
              // value={member.email}
              value={viewEmail ? member.email : ""}
              required
              onChange={(e) => handleMailChange(e, member.id, index, type)}
              onBlur={(e) => handleOnBlur(e, member.id, index, type)}
            />
            <button
              className="btn-clear"
              type="reset"
              title={t("clearfield")}
              onClick={(e) => clearEmail(e, member.id, index, type)}
            >
              {t("clearfield")}
            </button>
          </div>
          <div className="form-group">
            <label htmlFor="mobile">
              {t("mobilenumber")} ({t("optional")})
            </label>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              // value={member.mobile}
             // value={member.mobile ? member.mobile : ""}
             value={showPhone ? member.mobile : ""}
              maxLength={10}
              onBlur={(e) => handleOnBlur(e, member.id, index, type)}
              onChange={(e) => handleMobileChange(e, member.id, index, type)}
            />
          </div>
        </div>
      );
    });
  };

  const emailUpdated = () => {
    return (
      _.some(systemBoardMembers, { email: null }) ||
      _.some(manualBoardMembers, { email: null }) ||
      _.some(systemBoardMembers, { email: "" })
    );
  };

  return (
    <div
      className={`container createuser has-overlay ${
        toggleNavigation ? " has-navigation" : ""
      } ${toggleHelp ? " has-help" : ""} ${
        loading && "has-spinner has-overlay"
      }`}
    >
      <main className="overlay">
        <button
          className="btn btn-close"
          onClick={() =>
            !_.isNull(documentId)
              ? props.history.push(
                  `/members/${orgNumber}/documentId/${documentId}/broker/${person_number}`
                )
              : props.history.push(`/members/${orgNumber}`)
          }
        >
          {t("close")}
        </button>
        <div className="app">
          <h1>{t("complete")}</h1>
          <p>{t("complete_contactinfo_text")}</p>
          {loading && <OverlaySpinner />}
          <form>
            {systemBoardMembers && renderForm("system")}
            {manualBoardMembers && renderForm("manual")}
            <div className="button-group__centered">
              <button
                type="button"
                className="btn btn-link"
                onClick={() =>
                  !_.isNull(documentId)
                    ? props.history.push(
                        `/members/${orgNumber}/documentId/${documentId}/broker/${person_number}`
                      )
                    : props.history.push(`/members/${orgNumber}`)
                }
              >
                {t("cancel")}
              </button>
              <button
                onClick={handleNextEvent}
                type="button"
                className={`btn ${
                  emailUpdated() ? "btn-disabled" : "btn-primary"
                }`}
              >
                {t("next")}
              </button>
            </div>
          </form>
        </div>
      </main>
      <Navigation toggleNavigation={toggleNavigation} />
      <Footer
        setToggleNavigation={setToggleNavigation}
        toggleNavigation={toggleNavigation}
        setToggleHelp={setToggleHelp}
        toggleHelp={toggleHelp}
      />
    </div>
  );
}
export default withTranslation()(UpdateMember);
