import React, { useState } from "react";
import "../styles/main.scss";
import Header from "./Header";
import Footer from "./Footer";
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";
import Navigation from "./Navigation";
import { Link } from "react-router-dom";
import { withTranslation} from "react-i18next";

function Faq(props) {
  console.log(props);
  const { t } = props
  const [toggleNavigation, setToggleNavigation] = useState(false)
  const [toggleHelp, setToggleHelp] = useState(false)

  return (
    <div className={`container articlepage faq ${toggleNavigation ? " has-navigation" : ""} ${toggleHelp ? " has-help" : ""}`}>
      {/* <Alert /> */}
      <Header {...props} />
      <main>
        <MainHeader />
        <div className="main-content">
          <article>
            <h1>Vanliga frågor</h1>
            <p>
              Text
            </p>
            <p><br /></p>
            <p className="button-group__centered">
              <Link to="/bli-kund-digital-arsredovisning" className="btn btn-primary">
                {t("usesignatornow")}!
            </Link>
            </p>
          </article>
          <MainFooter setToggleNavigation={setToggleNavigation} toggleNavigation={toggleNavigation} setToggleHelp={setToggleHelp} toggleHelp={toggleHelp} />
        </div>
      </main>
      <Navigation toggleNavigation={toggleNavigation} />
      <Footer setToggleNavigation={setToggleNavigation} toggleNavigation={toggleNavigation} setToggleHelp={setToggleHelp} toggleHelp={toggleHelp} />
    </div>
  );
}
export default withTranslation()(Faq);
