import React, { useState, useEffect } from "react";
import "../styles/main.scss";
import config from "../utils/config";
import _ from "lodash";
import apicall from "../apis/apicall";
import { withTranslation} from "react-i18next";
import helpers from "../utils/helpers";

function DeleteAccountant(props) {
  console.log(props);
  const { t } = props;
  const [manualBoardMembers, setmanualBoardMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [orgNumber, setorgNumber] = useState();
  const memberId = parseInt(props.match.params.memberId);
  const [name, setname] = useState("")
  const [company, setcompany] = useState();
  const [documentId, setdocumentId] = useState(null);
  const [person_number, setperson_number] = useState(null);

  const fetchCachedBOrgNumber = async () => {
    await config.LOCAL_FORAGE.getItem("org-number").then((resp) => {
      setorgNumber(resp);
      setLoading(false);
    });
    await config.LOCAL_FORAGE.getItem("company").then((value) => {
      setcompany(value);
    });
  };


  const fetchCachedManualMembers = async () => {
    await config.LOCAL_FORAGE.getItem("manual-members").then((resp) => {
      let x = resp.find(x => x.id === memberId)
      console.log(x)
      setname(`${x.firstName} ${x.lastName}`)
      setmanualBoardMembers(resp);
    });
  };

  const fetchDocumentId = async () => {
    await config.LOCAL_FORAGE.getItem("documentId").then((resp) => {
      setdocumentId(resp);
    });
    await config.LOCAL_FORAGE.getItem("person_number").then((resp) => {
      setperson_number(resp);
    });
  };


  useEffect(() => {
    fetchCachedBOrgNumber();
    fetchCachedManualMembers();
    fetchDocumentId();
  }, []);

  const removeDeletedMember = (memberId) => {
    const idToRemove = memberId;
    const filternewArray = manualBoardMembers
      .slice()
      .filter((items) => items.id !== idToRemove);
    setmanualBoardMembers(filternewArray);
    config.LOCAL_FORAGE.setItem("manual-members", filternewArray, (err) =>
      console.log(err)
    );
  };

  const handleClick = () => {
    // props.history.push(`/members/${orgNumber}`);
    !_.isNull(documentId)
    ? props.history.push(
        `/members/${orgNumber}/documentId/${documentId}/broker/${person_number}`
      )
    : props.history.push(`/members/${orgNumber}`)
  };

  const deleteRavisor = (memberId) => {
    setLoading(true);
    apicall.deleteRavisor(memberId).then((response) => {
      setLoading(false);
      if (response.status === 202) {
        // helpers.notify(t("msg_deletemembersuccess"));
        // props.history.push(`/members/${orgNumber}`);
        !_.isNull(documentId)
        ? props.history.push(
            `/members/${orgNumber}/documentId/${documentId}/broker/${person_number}`
          )
        : props.history.push(`/members/${orgNumber}`)
        removeDeletedMember(memberId);
      } else {
        helpers.notify(t("msg_deletememberfail"));
        // props.history.push(`/members/${orgNumber}`);
        !_.isNull(documentId)
        ? props.history.push(
            `/members/${orgNumber}/documentId/${documentId}/broker/${person_number}`
          )
        : props.history.push(`/members/${orgNumber}`)
      }
    });
  };


  return (
    <div className="container overlay-delete-accountant">
    <main style={{padding:"15px"}}>
      <h2>Ta bort revisor</h2>
      <p>
        {`Du är på väg att ta bort ${name} från ${company}. Detta går
        inte att ångra.`}
      </p>
      <p>Är du säker?</p>
      <button className="btn btn-link" onClick={() => handleClick()}>
        Avbryt
      </button>
      <button
        className="btn btn-success"
        onClick={() => deleteRavisor(memberId)}
      >
        Ta bort
      </button>
    </main>
    </div>
  );
}
export default withTranslation()(DeleteAccountant);
