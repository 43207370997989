import React, { useState } from "react";
import "../styles/main.scss";
import Footer from "./Footer";
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";
import Navigation from "./Navigation";
import config from "../utils/config";
import apicall from "../apis/apicall";
import OverlaySpinner from "./OverlaySpinner";
import { withTranslation} from "react-i18next";
// ALL CSS are added just for demonstrative purpose

function UploadDocument(props) {
  const [toggleNavigation, setToggleNavigation] = useState(false)
  const [toggleHelp, setToggleHelp] = useState(false)
  const [fileUploadButton, setfileUploadButton] = useState(true);
  const { t } = props;
  const orgNumber = props.match.params.orgNumber;
  const [loading, setLoading] = useState(false);


  const onSelectFile = (e) => {
    setLoading(true);
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    setfileUploadButton(false);
    // setSelectedFile();
    apicall.uploadAgreement(e.target.files[0], t).then((resp) => {
      console.log(resp);
      setLoading(false);
      if (resp.status == 200) {
        config.LOCAL_FORAGE.setItem("path", resp.data, (err) =>
          console.log(err)
        );
        // helpers.notify(t("msg_documentuploaded"));
        props.history.push(`/detail/${orgNumber}`);
      }
    });
  };


  return (
    <div
      className={`container upload-document 
      ${toggleNavigation ? " has-navigation" : ""} 
      ${toggleHelp ? " has-help" : ""}  
      ${ loading && "has-spinner has-overlay"}`}>
      <main>
        <MainHeader {...props} />
        <div className="app">
          <div>
            <p className="text-align__center first-letter">
              {t("upload")} {t("document")} (pdf)
          </p>
            {fileUploadButton ? (
              <button className="btn btn-add btn-add-primary">
                {t("upload")} {t("document")}
              </button>
            ) : (
                <div></div>
              )}
            <input
              type="file"
              name="upload"
              accept="application/pdf"
              onChange={onSelectFile}
            />
            {loading && <OverlaySpinner />}
          </div>
        </div>
        <MainFooter setToggleNavigation={setToggleNavigation} toggleNavigation={toggleNavigation} setToggleHelp={setToggleHelp} toggleHelp={toggleHelp} />
      </main>
      <Navigation toggleNavigation={toggleNavigation} />
      <Footer setToggleNavigation={setToggleNavigation} toggleNavigation={toggleNavigation} setToggleHelp={setToggleHelp} toggleHelp={toggleHelp} />
    </div>
  );
}
export default withTranslation()(UploadDocument);
