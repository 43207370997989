import React from 'react'
import { withTranslation} from "react-i18next";

function Footer(props) {
    const { t } = props
    return (
        <footer>
            <nav>

                {
                    props.hasOwnProperty('toggleNavigation') ?
                        <button onClick={() => props.setToggleNavigation(!props.toggleNavigation)} className="btn btn-navigation">Navigation</button>
                        : <button className="btn btn-navigation">Navigation</button>
                }
                <a href="/" className="logo" title={t("home")}>
                    {t("home")}
                </a>
                {
                    props.hasOwnProperty('toggleHelp') ?
                        <button onClick={() => props.setToggleHelp(!props.toggleHelp)} className="btn btn-help">
                            {t("gethelp")}
                        </button>
                        : <button className="btn btn-help">
                            {t("gethelp")}
                        </button>
                }
            </nav>
        </footer>
    )
}
export default withTranslation()(Footer);
