import React, { useState, useEffect } from "react";
import "../styles/main.scss";
import Footer from "./Footer";
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";
import Navigation from "./Navigation";
import config from "../utils/config";
import _ from "lodash";
import apicall from "../apis/apicall";
import helpers from "../utils/helpers";
import { withTranslation } from "react-i18next";
import OverlaySpinner from "./OverlaySpinner";
import { useParams } from "react-router-dom";

function SmoothBoardMembers(props) {
  const [toggleNavigation, setToggleNavigation] = useState(false);
  const [toggleHelp, setToggleHelp] = useState(false);
  const { t } = props;
  const [boardMembers, setboardMembers] = useState([]);
  const orgNumber = !_.isEmpty(props.match.params)
    ? props.match.params.orgNumber
    : "";
  const [company, setcompany] = useState();
  const [loading, setLoading] = useState(true);
  const [hasAccountant, sethasAccountant] = useState(false);
  const [addAccountant, setaddAccountant] = useState();
  const [systemBoardMembers, setsystemBoardMembers] = useState([]);
  const [manualBoardMembers, setmanualBoardMembers] = useState([]);
  const [valid, setvalid] = useState(true);
  const [selectedBoardMembers, setselectedBoardMembers] = useState([]);
  const [document_type, setdocument_type] = useState();

  const documentId = !_.isEmpty(props.match.params)
    ? props.match.params.documentId
    : "";
    const person_number = !_.isEmpty(props.match.params)
    ? props.match.params.person_number
    : "";

  const fetchBoardMembers = () => {
    setLoading(true);
    apicall.getCompany(props.match.params.orgNumber,true).then((resp) => {
      if (resp) {
        const datetime = new Date();
        config.LOCAL_FORAGE.setItem("date-time", datetime);
        config.LOCAL_FORAGE.setItem("org-number", resp.orgNumber);
        config.LOCAL_FORAGE.setItem("company", resp.name, (err) =>
          setTimeout(() => {
            setcompany(resp.name);
          }, 1000)
        );
        // config.LOCAL_FORAGE.setItem("selected-members", [], (err) =>
        //   console.log(err)
        // );
        config.LOCAL_FORAGE.setItem(
          "board-members",
          resp.boardMembers,
          (err) => setboardMembers(resp.boardMembers)
          // console.log(err)
        );
        config.LOCAL_FORAGE.setItem("document_type", "other_document", (err) =>
          setTimeout(() => {
            setdocument_type("other_document");
          }, 1000)
        );
        config.LOCAL_FORAGE.setItem("documentId", documentId);
        config.LOCAL_FORAGE.setItem("person_number", person_number);
        setLoading(false);
      }
      else {

        helpers.notify(t("msg_orgnrnotvalid"));
      }
    });
  };

  const fetchCachedManualMembers = async () => {
    await config.LOCAL_FORAGE.getItem("manual-members").then((resp) => {
      setmanualBoardMembers(resp);
    });
  };
  const fetchSelectedBoardMembers = async () => {
    await config.LOCAL_FORAGE.getItem("selected-members").then((resp) => {
      setselectedBoardMembers(resp);
    });
  };

  useEffect(() => {
    const datetime = new Date();
    config.LOCAL_FORAGE.setItem("date-time", datetime);
    config.LOCAL_FORAGE.setItem("org-number", "");
    config.LOCAL_FORAGE.setItem("company", "");
    config.LOCAL_FORAGE.setItem("document_type", "other_document", (err) =>
      setTimeout(() => {
        setdocument_type("other_document");
      }, 1000)
    );
    config.LOCAL_FORAGE.setItem("documentId", documentId);
    config.LOCAL_FORAGE.setItem("person_number", person_number);
    setTimeout(() => {
      fetchBoardMembers();
      // fetchCachedManualMembers();
      // fetchSelectedBoardMembers();
    }, 1000);
  }, []);

  useEffect(() => {
    fetchSelectedBoardMembers();
    fetchCachedManualMembers();
  }, []);

  useEffect(() => {
    processData();
  }, [boardMembers, selectedBoardMembers]);

  const removeDeletedMember = (memberId) => {
    const idToRemove = memberId;
    const filternewArray = manualBoardMembers
      .slice()
      .filter((items) => items.id !== idToRemove);
    setmanualBoardMembers(filternewArray);
    config.LOCAL_FORAGE.setItem("manual-members", filternewArray, (err) =>
      console.log(err)
    );
  };

  const deleteRavisor = (memberId) => {
    setLoading(true);
    // apicall.deleteRavisor(memberId).then((response) => {
    //   setLoading(false);
    //   if (response.status === 202) {
    //     // helpers.notify(t("msg_deletemembersuccess"));
    //     removeDeletedMember(memberId);
    //   } else {
    //     helpers.notify(t("msg_deletememberfail"));
    //   }
    // });
    props.history.push(`/showMember/${memberId}`);
  };

  const processData = () => {
    let systemArray = [];
    boardMembers.map((boardMember, index) => {
      boardMember.memberRoles.map((role, index) => {
        if (
          config.systemMembers.includes(role.name) &&
          boardMember.memberType === "PERSON" &&
          boardMember.createdType === "SYSTEM"
        ) {
          // console.log(role)
          let x = helpers.constructMemberObject(role, boardMember, false);
          systemArray = [...systemArray, x];
        }
      });
    });
    config.LOCAL_FORAGE.setItem("system-members", systemArray, (err) =>
      console.log(err)
    );

    config.LOCAL_FORAGE.setItem(
      "selected-members",
      selectedBoardMembers,
      (err) => console.log(err)
    );

    setsystemBoardMembers(systemArray);
  };

  const handleCheckboxChange = (event) => {
    const checkboxId = event.target.id;
    const isChecked = event.target.checked;

    if (isChecked) { 
      setselectedBoardMembers((prevselectedBoardMembers) => [
        ...(prevselectedBoardMembers || []), 
        checkboxId,
      ]);
      // console.log("------------------",selectedBoardMembers)
    } else {
      setselectedBoardMembers((prevselectedBoardMembers) =>
        prevselectedBoardMembers.filter((id) => id !== checkboxId)
      );
      // console.log("------------------",selectedBoardMembers)
    }
  };

  const displayBoardMembers = () => {
    const sortedBoardMembers = _.sortBy(systemBoardMembers, ["role"]);
    return sortedBoardMembers.map((member, index) => {
      if (!hasAccountant && document_type === "other_document")
        sethasAccountant(true);
      else if (!hasAccountant && config.accountants.includes(member.role))
        sethasAccountant(true);
      return (
        <tr className="row-member">
          {document_type !== "other_document" && (
            <td className="member-role">{member.role}</td>
          )}
          <td className="member-name">{member.name}</td>
          {document_type === "other_document" && (
            <td>
              <input
                type="checkbox"
                className="form-check-input"
                id={member.id}
                onChange={handleCheckboxChange}
                checked={
                  !_.isNull(selectedBoardMembers) &&
                  selectedBoardMembers.includes(member.id.toString())
                    ? true
                    : false
                }
              />
            </td>
          )}
        </tr>
      );
    });
  };

  const displayManualBoardMembers = () => {
    // console.log('displayManualBoardMembers',manualBoardMembers)
    const sortedBoardMembers = _.sortBy(manualBoardMembers, ["role"]);
    return sortedBoardMembers.map((member, index) => {
      if (!hasAccountant && config.accountants.includes(member.role))
        sethasAccountant(true);
      return (
        <tr className="row-member">
          {document_type !== "other_document" && (
            <td className="member-role">{member.role}</td>
          )}
          <td className="member-name">{member.name}</td>
          {document_type === "other_document" && (
            <td>
              <input
                type="checkbox"
                className="form-check-input"
                id={member.id}
                onChange={handleCheckboxChange}
                checked={
                  !_.isNull(selectedBoardMembers) &&
                  selectedBoardMembers.includes(member.id.toString())
                    ? true
                    : false
                }
              />
            </td>
          )}
          <button
            id={member.id}
            className="btn btn-remove"
            type="reset"
            title={t("remove", "member")}
            onClick={() => deleteRavisor(member.id)}
          >
            {t("deletemember")}
          </button>
        </tr>
      );
    });
  };

  const handleSubmit = () => {
    if (_.some(manualBoardMembers, { role: "REVIN" })) {
      let x =
        _.some(manualBoardMembers, { role: "REVEX" }) ||
        _.some(systemBoardMembers, { role: "REV" }) ||
        _.some(systemBoardMembers, { role: "REVL" }) ||
        _.some(systemBoardMembers, { role: "REVT" }) ||
        _.some(systemBoardMembers, { role: "REVH" });
      if (!x) {
        setvalid(false);
      } else {
        setvalid(true);
        props.history.push(`/update/${orgNumber}`);
      }
    } else {
      setvalid(true);
      props.history.push(`/update/${orgNumber}`);
    }
    console.log(valid);
  };
  // console.log("has accountant", hasAccountant);
  return (
    <div
      className={`container board-members ${
        toggleNavigation ? " has-navigation" : ""
      } ${toggleHelp ? " has-help" : ""} ${
        loading ? "has-spinner has-overlay" : ""
      }`}
    >
      <main>
        <MainHeader {...props} />
        <div className="app">
          {<OverlaySpinner />}
          <div>
            <h1>
              {company}
              <br />
              <div className="org-number">{orgNumber}</div>
            </h1>
            <p className="text-align__center">{t("select_who_should_sign")}</p>
          </div>
          <table className="table-members-list">
            <thead>
              <tr>
                {document_type !== "other_document" && <th>{t("role")}</th>}
                <th>{t("name")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {_.isEmpty(systemBoardMembers) &&
                _.isEmpty(manualBoardMembers) && (
                  <tr>
                    <td>
                      <p>{t("boardmembers_not_present")}</p>
                    </td>
                  </tr>
                )}
              {systemBoardMembers && displayBoardMembers()}
              {manualBoardMembers && displayManualBoardMembers()}
            </tbody>
          </table>
          {document_type === "yearly_revenue" &&
            !hasAccountant &&
            !_.isEmpty(systemBoardMembers) && (
              <div>
                <p>{t("boardmembers_text")}</p>

                <div className="form-check form-check-inline">
                  <input
                    id="yes"
                    value="yes"
                    checked={addAccountant === "yes"}
                    type="radio"
                    className="form-check-input"
                    onChange={(e) => {
                      setaddAccountant(e.target.value);
                      props.history.push(`/add-user/${orgNumber}`);
                    }}
                  />
                  <label for="yes" className="form-check-label">
                    {t("yes")}
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="no"
                    value="no"
                    checked={addAccountant === "no"}
                    className="form-check-input"
                    type="radio"
                    onChange={(e) => setaddAccountant(e.target.value)}
                  />
                  <label for="no" className="form-check-label">
                    {t("no")}
                  </label>
                </div>
              </div>
            )}

          {addAccountant !== "no" && hasAccountant && (
            <div className="form-group centered">
              <button
                type="button"
                onClick={() => props.history.push(`/add-user/${orgNumber}`)}
                className="btn btn-text"
              >
                <i className="icon icon-add"></i>
                {t("createuser_addadditionalcontacts")}{" "}
              </button>
            </div>
          )}
          <div>{!valid && <p style={{ color: "red" }}>{t("reverror")}</p>}</div>
          {(addAccountant === "no" || hasAccountant) && (
            <button
              onClick={handleSubmit}
              style={{ marginTop: "1em" }}
              className="btn btn-success"
              disabled={_.isEmpty(selectedBoardMembers) ? true : false}
            >
              {t("next")}
            </button>
          )}
        </div>
        <MainFooter
          setToggleNavigation={setToggleNavigation}
          toggleNavigation={toggleNavigation}
          setToggleHelp={setToggleHelp}
          toggleHelp={toggleHelp}
        />
      </main>
      <Navigation toggleNavigation={toggleNavigation} />
      <Footer
        setToggleNavigation={setToggleNavigation}
        toggleNavigation={toggleNavigation}
        setToggleHelp={setToggleHelp}
        toggleHelp={toggleHelp}
      />
    </div>
  );
}
export default withTranslation()(SmoothBoardMembers);
