import React from "react";
import { Link } from "react-router-dom";
import MainFooter from "./MainFooter";
import { withTranslation } from "react-i18next";

function StartContentEN(props) {
    const { t } = props

    return (
        <div className="main-content">
            <article>
                <div className="hero">
                    <div className="logo"></div>
                    <h1>Welcome <span className="show-on-desktop">to Signator</span></h1>
                    <p className="preamble">
                        Med Signator kan flera personer underteckna dokument digitalt, t ex årsredovisning, avtal etc, utan att behöva träffas då signeringen sker digitalt.
                        Tjänsten är det enklaste, snabbaste och tryggaste sättet att signera dokument. Ni kan med hjälp av BankID bli klara på några minuter, vart än ni
                        än befinner er.
                    </p>
                    <p className="button-group__centered">
                        <Link to={`${props.isGuide ? "/valj-dokument-typ" : "/bli-kund-digital-arsredovisning"}`} className="btn btn-primary hide">
                            {t("usesignatornow")}!
                        </Link>
                        <Link to="/valj-dokument-typ" className="btn btn-primary">
                            {t("usesignatornow")}!
                        </Link>
                    </p>
                    <p className="button-group__centered">
                        <Link to="/guide" className="btn btn-light">
                            {t("userguide")}
                        </Link>
                    </p>
                </div>
                <h2>E-signera dokument, avtal, årsredovisning etc, juridiskt korrekt, tryggt, snabbt och busenkelt.</h2>
                <p>Signator säkerställer att alla personer informeras (och påminns) om att dokumentet är klart för signering med BankID.</p>
                <p>Signator ser till att:<br />
                    • Rätt person - och enbart dessa - skriver på<br />
                    • Revisor, om sådan finns, informeras när samtliga ledamöter skrivit på en årsredovisning, som sedan elektroniskt kan e-signera och bifoga sin revisionsberättelse till handlingarna<br />
                </p>
                <img src="/start-img-01.png" alt=""></img>
                <h2>Glöm hur det var förut</h2>
                <p>När ni använt Signator lär ni snabbt glömma hur jobbigt och tidsödande det varit tidigare, att behöva samla in alla signaturer på ett avtal eller årsredovisning - då man tvingades<br />
                    • arrangera så att alla kunde träffas, eller boka individuella besökstider, för att signera, eller<br />
                    • skicka dokumentet på en rundgång med posten: då den ena styrelseledamoten skrev sin signatur, skickade med post hem till nästa ledamot, som inte hade frimärken men omsider
                    fick köpt det, skrev på och, efter att ha letat fram hennes mest troliga postadress, skickade till nästa ledamot, som var bortrest på tjänsteresa eller semester … Aaaargh, vilket elände: inte snabbt, inte tryggt!</p>
                <h2>Det finns enbart fördelar med Signator</h2>
                <p>Signator eliminerar de gamla problemen och hjälper er att:<br />
                    • spara jobbigt administrationsarbete<br />
                    • spara en massa tid<br />
                    • spara miljön<br />
                    • spara pengar<br />
                    på ett korrekt, kvalitetssäkrat och helt tryggt sätt.</p>
                <ul className="steps steps-horizontal">
                    <li className="step01">
                        Ange org.nr
                    </li>
                    <li className="step03">
                        Ladda upp dokument (pdf)
                    </li>
                    <li className="step04">
                        Avisera parterna
                    </li>
                    <li className="step05">
                        Granska och e-signera
                    </li>
                    <li className="step06">
                        Färdigt!
                    </li>
                </ul>
                <h2>Om Signator</h2>
                <p>Signator är en e-signeringstjänst från Sveriges Mäklar- och Organisationstjänst AB (org.nr 559042-1631) och tillhandahålls av Signator AB (556246-9881). Tjänsten är byggt på avancerad, trygg och legitimerad teknologi, bl.a. BankID. </p>
                <p>Signator är skräddarsydd för effektiv användning i organisationer/företag som finns registrerade hos Bolagsverket. Alla, t ex registrerade styrelseledamöter samt revisor, kan enkelt använda tjänsten. </p>
                <p>Med Signator kan alla ledamöter e-signera Årsredovisningen - och revisorn inkludera sin revisionsberättelse - enklare och snabbare än med någon annan e-tjänst. </p>
                <p>Signator kan även användas för att e-signera andra dokument, t.ex. styrelseprotokoll och avtal. </p>
                <h2>Våra tjänster</h2>
                <ul>
                    <li>E-signering av Årsredovisning (utan samt inklusive revisors revisionsberättelse)</li>
                    <li>E-signering av styrelseprotokoll (lanseras snart)</li>
                    <li>E-signering av avtal (lanseras snart)</li>
                </ul>
                <p><br /></p>
                <p className="button-group__centered">
                    <Link to="/guide" className="btn btn-light">
                        {t("userguide")}
                    </Link>
                </p>
                <h2>Vad säger styrelseledamoten?</h2>
                <blockquote>
                    <p>“Jag har erfarenhet av arbete i nästan 20 styrelser. Efter att jag slutate jobba heltid är jag kvar som ledamot i en handfull småbolag. Vi har ofta styrelsemöter på distans, det har varit oproblematiskt de senaste 25 åren. Det har däremot inte signeringen av Årsredovisningen. Eller snarare allt kring det. Ack, för ett elände.
                        Det mesta av året bor jag i stan men i perioden maj-augusti lever jag i min stuga. På en ö. Utan postadress. </p>
                    <p>Alla tidigare år har jag, efter att jag kontaktats om att en Årsredovisning postats till min adress i stan, fått åka dit för att kolla om den kommit. Samma procedur för varenda Årsredovisning, från varje bolag. Resa till stan, kolla efter post, skriva på (om posten kommit), posta vidare/tillbaka. </p>
                    <p>Med Signator sparar jag totalt 20 timmar i faktisk och detsamma i ren frustrationstid varje Årsredovisningsperiod. Det är nog få som kan göra det. Men alla organisationer och dess styrelseledamöter tjänar på att använda Signator. Tid är pengar. Och det är busenkelt att använda tjänsten. </p>
                    <p>Visa att ni tänker affärsmässigt på alla plan - använd Signator!” </p>
                </blockquote>
                <p>– Morten Olsen, ekon.dr,  ordf. Dunderdog AB, ledamot Wisebee Group AB m.fl</p>
                <h2>Vad säger revisorn?</h2>
                <blockquote>
                    <p>“Som revisor för fler än 150 bostadsrättsföreningar kan jag intyga att Signator kommer som en hjälp från ovan. Jag har varit van med att hantera stora mängder post från mina klienter, i maj och juni har brevlådan varit full i princip varje dag.                </p>
                    <p>När jag istället får tillgång till färdiga PDF-Årsredovisningar som signerats digitalt med Signator reduceras den administrativa bördan enormt. Nu slipper jag vara bunden till en brevlåda och se till att det finns toner, kuvert, porto och korrekta svarsadresser tillgängliga. Äntligen kan jag fokusera helt på revisionen istället för på administrationen. Signator gör det dessutom lätt att att lägga min revisionsberättelse till Årsredovisningen. Allt klart! Det är ingen överdrift att säga att Signator gör livet som revisor mer lustfyllt.                 </p>
                    <p>Signator är även en stor hjälp för många av mina klienter, inte minst nu under Coronapandemien. I år har styrelseledamöter hos ett större antal föreningar har inte haft möjlighet eller vågat träffas för att skriva på Årsredovisningen. Flera har dessutom varit utomlands och/eller i karantän vid tidpunkten för Årsredovisningens inlämnande. Och i några fall har ledamöterna inte ens velat hantera vanlig post.</p>
                    <p>Min förhoppning är att så många av mina klienter som möjligt kommer använda Signator för Årsredovisningen för all framtid.”</p>
                </blockquote>
                <p>– David Walman, Revisor, Rävisor AB</p>
            </article>
            <MainFooter />
        </div>
    );
}
export default withTranslation()(StartContentEN);
