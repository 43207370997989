import React, { useState, useEffect } from "react";
import "../styles/main.scss";
import "../styles/components/_modal.scss";
import _ from "lodash";
import Footer from "./Footer";
import MainHeader from "./MainHeader";
import apicall from "../apis/apicall";
import Modal from "./UpdateModal";
import { withTranslation } from "react-i18next";
import helpers from "../utils/helpers";
import OverlaySpinner from "./OverlaySpinner";
import config from "../utils/config";
function Summary(props) {
  console.log("Summary", props);
  const [member, setmember] = useState();
  const [agreement, setagreement] = useState({});
  const [show, setshow] = useState(false);
  const agreementId = props.match.params.agreementid;
  const orgNumber = props.match.params.orgNumber;
  const [loading, setLoading] = useState(true);
  const [signingModal, setsigningModal] = useState(false);
  const company = "";
  const { t, location } = props;
  const [companyName, setcompanyName] = useState(
    !_.isEmpty(location.state) ? location.state.companyName : ""
  );

  const [document_type, setdocument_type] = useState();
  const [path, setpath] = useState();
  const [document1_path, setdocument1_path] = useState();
  const [document2_path, setdocument2_path] = useState();


  const fetchDocumentType = async () => {
    await config.LOCAL_FORAGE.getItem("document_type").then((resp) => {
      setdocument_type(resp);
    });
  };

  const fetchCachedPath = async () => {
    await config.LOCAL_FORAGE.getItem("path").then((value) => {
      setpath(value);
    });
  };

  useEffect(() => {
    fetchDocumentType();
    fetchCachedPath();
  });

  const handleClose = (x) => {
    console.log("came here");
    setshow(!show);
    setmember(x);
    if (!x) fetchAgreement();
  };
  const fetchAgreement = async () => {
    await config.LOCAL_FORAGE.getItem("document_type").then((resp) => {
      apicall.getAgreement(agreementId).then((resp) => {
        setLoading(false);
        setagreement(resp.data);
        setdocument1_path(resp.data.uploadedDocument);
        setdocument2_path(resp.data.signedDocument)
      });
    });
  };

  useEffect(() => {
    fetchAgreement();
    if (_.isEmpty(companyName)) getOrg();
  }, []);

  const getOrg = () => {
    setLoading(true);
    apicall.getOrg(orgNumber).then((resp) => {
      setLoading(false);
      if (_.isEmpty(resp)) {
        // getCompany(orgNum);
      } else {
        let org = _.has(resp, "organization") ? resp.organization : resp;
        setcompanyName(org.business);
      }
    });
  };

  const constructTable = () => {
    const members = agreement.orderYealyRevMembersList;
    if (!_.isEmpty(members)) {
      const sortedMembers = _.sortBy(members, ["role"]);
      return sortedMembers.map((boardMember, index) => {
        let fname = boardMember.firstName ? boardMember.firstName : "";
        let lname = boardMember.lastName ? boardMember.lastName : "";
        return (
          <tr onClick={() => handleClose(boardMember)} className="row-member">
            {document_type !== "other_document" && (
              <td className="member-role">{boardMember.role}</td>
            )}
            <td className="member-name">
              <div>{`${fname} ${lname}`}</div>
            </td>
            <td className="member-contactinfo" title={`${boardMember.email} ${
                boardMember.mobile !== "null" ? boardMember.mobile : ""
              }`}>
              <div>{`${boardMember.email} ${
                boardMember.mobile !== "null" ? boardMember.mobile : ""
              }`}</div>
            </td>
            <td className="status">
              <div className={boardMember.agreementState}></div>
            </td>
          </tr>
        );
      });
    }
  };

  const constructSigniningLinkTable = () => {
    const members = agreement.orderYealyRevMembersList;
    if (!_.isEmpty(members)) {
      console.log("memebrs---->", members);
      const sortedMembers = _.sortBy(members, ["role"]);
      return sortedMembers.map((boardMember, index) => {
        if (agreement.agreementType !== 'OTHER' && boardMember.role != "LE") return null;
        let fname = boardMember.firstName ? boardMember.firstName : "";
        let lname = boardMember.lastName ? boardMember.lastName : "";
        return (
          <tr onClick={() => setsigningModal} className="row-member">
            <td className="member-role">{boardMember.role}</td>
            <td className="member-name">
              <a
                href={
                  _.has(boardMember, "signingLink") && boardMember.signingLink
                }
                target="_blank"
              >{`${fname} ${lname}`}</a>
            </td>
            <td className="member-contactinfo">
              <div>{`${boardMember.email} ${
                boardMember.mobile !== "null" ? boardMember.mobile : ""
              }`}</div>
            </td>
            <td className="status">
              <div className={boardMember.agreementState}></div>
            </td>
          </tr>
        );
      });
    }
  };

  const SigningModal = () => {
    return (
      <div className="app">
        <h1>{t("click_on_name_tosign")}</h1>
        <table className="table-members-list">
          <thead>
            <tr>
              <th>{t("role")}</th>
              <th>{t("name")}</th>
              <th>{t("contactinfo")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{constructSigniningLinkTable()}</tbody>
        </table>
      </div>
    );
  };

  const renderPage = () => {
    if (signingModal) {
      return (
        <main className="overlay">
          <button
            className="btn btn-close"
            onClick={() => setsigningModal(false)}
          >
            {t("close")}
          </button>{" "}
          <SigningModal />
        </main>
      );
    } else if (show) {
      return (
        <main className="overlay">
          <button className="btn btn-close" onClick={handleClose}>
            {t("close")}
          </button>{" "}
          <Modal
            show={show}
            handleClose={handleClose}
            member={member}
            className="translate-this"
          />
        </main>
      );
    } else {
      return (
        <main>
          <MainHeader {...props} />
          <div className="app">
            {loading && <OverlaySpinner />}
            <h1>{t("signaturestatus")}</h1>
            <div className="form-group">
              <label>{t("organizationnumber")}</label>
              <p>{orgNumber}</p>
            </div>
            <div className="form-group">
              <label>{t("associationcompany")}</label>
              <p>{companyName}</p>
            </div>
            <div className="form-group document">
              <label>{t("document")}</label>
              {/* <p className="ellipsis">
                  {helpers.getFilename(agreement.uploadedDocument)}
                </p> */}

              {/* {agreement.agreementType === "OTHER" ? (
                <p className="ellipsis">OtherDocument.pdf</p>
              ) : (
                <p className="ellipsis">Årsredovisning.pdf</p>
              )} */}
              {agreement.agreementType === "OTHER"? (
              <p className="ellipsis">{helpers.getFilename(document1_path)}</p>
            ) : (
              <p className="ellipsis">
                {helpers.getFilenameWithTimeStamp(document1_path)}
              </p>
            )}
              <a
                href={helpers.encodePath(agreement.uploadedDocument)}
                target="_blank"
                className={
                  agreement.uploadedDocument ? "icon icon-document-1" : ""
                }
              >
                {" "}
              </a>
            </div>
           { !_.isNull(document2_path)  && <div className="form-group document">
              <label>{t("signed_document")}</label>
              {/* <p className="ellipsis">
                  {helpers.getFilename(agreement.uploadedDocument)}
                </p> */}

              {/* {agreement.agreementType === "OTHER" ? (
                <p className="ellipsis">OtherDocument.pdf</p>
              ) : (
                <p className="ellipsis">Årsredovisning.pdf</p>
              )} */}
              {agreement.agreementType === "OTHER"? (
              <p className="ellipsis">{helpers.getSignedFilename(document1_path)}</p>
            ) : (
              <p className="ellipsis">
                Signerad Årsredovisning inkl revisionsberättelse.pdf
              </p>
            )}
              <a
                href={helpers.encodePath(agreement.signedDocument)}
                target="_blank"
                className={
                  agreement.signedDocument ? "icon icon-document-1" : ""
                }
              >
                {" "}
              </a>
            </div>}
            <table className="table-members-list">
              <thead>
                <tr>
                  {document_type !== "other_document" && <th>{t("role")}</th>}
                  <th>{t("name")}</th>
                  <th>{t("contactinfo")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{constructTable()}</tbody>
            </table>
            <button
              onClick={() => setsigningModal(true)}
              className="btn btn-success"
            >
              {t("to_signing")}
            </button>
            {/* <div className="signing-note hide">{t("signlink_note")}</div> */}
          </div>
        </main>
      );
    }
  };

  return (
    <>
      <div
        className={`container detailview summary has-overlay ${
          loading ? "has-spinner has-overlay" : ""
        }`}
      >
        {renderPage()}
        <Footer />
      </div>
    </>
  );
}
export default withTranslation()(Summary);
