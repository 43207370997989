import React from "react";
import Spinner from "./Spinner";
import { withTranslation } from "react-i18next";

function OverlaySpinner({ msg }) {
  return (
    // temporary CSS
    <div style={{ width: "96%" }} className="overlay overlay-spinner">
      <div>{msg}</div>
      <Spinner />
    </div>
  );
}
export default withTranslation()(OverlaySpinner);
