import { toast } from "react-toastify";
import _ from "lodash";
import config from "./config";
const helpers = {
  isNumber: (string) => {
    return !isNaN(string);
  },
  isEmpty: (string) => {
    return _.isUndefined(string) || _.isNull(string) || _.isEmpty(string);
  },
  encodePath: (string) => {
    if (string) return `/preview/${string.replace(new RegExp("/", "g"), "--")}`;
  },
  decodePath: (string) => {
    if (string) return string.replace(new RegExp("--", "g"), "/");
  },
  notify: function (errorMsg) {
    toast(errorMsg);
  },
  getHeaders: async function () {
    let headers = {
      "Content-Type": "text/plain",
    };
    return headers;
  },
  getJsonHeaders: async function () {
    let headers = {
      "Content-Type": "application/json",
    };
    return headers;
  },
  validateEmail: function (email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  constructMemberObject: function (role, boardMember, ignoreEmail) {
    let x = {};
    x.role = role.name;
    x.rid = role.uuid;
    x.id = boardMember.id;
    x.name = boardMember.name;
    x.firstName = boardMember.firstName;
    x.lastName = boardMember.lastName;
    if (!ignoreEmail) {
      x.email = boardMember.email;
      x.mobile = boardMember.mobile;
    } else {
      x.email = null;
      x.mobile = null;
    }
    return x;
  },
  getFilename: (path) => {
    if (path) return path.split("\\").pop().split("/").pop();
  },
  getFilenameWithTimeStamp: (path) => {    
    if (path) 
  {  
    var fileName = path.split("\\").pop().split("/").pop();
    var extension=fileName.substr(fileName.lastIndexOf('.'));
    fileName=fileName.replace(extension,'')
    const regex = /_(\d{4}_\d{2}_\d{2}_\d{2}_\d{2}_\d{2})/;
    const cleanedFileName = fileName.replace(regex, extension);
    return cleanedFileName;
  }
  },
  getSignedFilename: (path) => {
    if(path){
      var fileName= path.split("\\").pop().split("/").pop().replace(/\.[^/.]+$/, "");
      return fileName+"-Signerad.pdf"
    }
    },
  createOptions: (brokerOffices) => {
    if (brokerOffices) {
      return brokerOffices.map((brokerOffice) => {
        let obj = {};
        obj.label = `${brokerOffice.orgnr_number} - ${brokerOffice.business}`;
        obj.value = brokerOffice.orgnr_number;
        obj.org = brokerOffice;
        return obj;
      });
    }
  },
  isNumString: (string) => {
    return /^\d+$/.test(string);
  },
  toFixedNumber: (num, digits, base) => {
    var pow = Math.pow(base || 10, digits);
    return Math.round(num * pow) / pow;
  },
  calculatePrice: (discount) => {
    if (discount) {
      let dis = (discount / 100) * config.annualPrice;
      let x = (config.annualPrice - dis).toFixed(2);
      return Number(x);
    } else {
      return config.annualPrice;
    }
  },
  calculateotherDocumentPrice: (discount) => {
    if (discount) {
      let dis = (discount / 100) * config.otherDocument_annualPrice;
      let x = (config.otherDocument_annualPrice - dis).toFixed(2);
      return Number(x);
    } else {
      return config.otherDocument_annualPrice;
    }
  },
  calculateVAT: (amt) => {
    let x = ((25 / 100) * amt).toFixed(2);
    return Number(x);
  },
  calculateTotal: (amt) => {
    return amt + helpers.calculateVAT(amt);
  },
};
export default helpers;
