import React, { useState } from "react";
import "../styles/main.scss";
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";
import Footer from "./Footer";
import GuideContentEN from "./content/GuideContentEN";
import GuideContentSE from "./content/GuideContentSE";
import Navigation from "./Navigation";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

function Guide(props) {
  console.log(props.i18n.language);
  const [toggleNavigation, setToggleNavigation] = useState(false)
  const [toggleHelp, setToggleHelp] = useState(false)
  return (
    <div className={`container guide ${toggleNavigation ? " has-navigation" : ""} ${toggleHelp ? " has-help" : ""}`}>
      <Helmet>
        <title>Guide | Signator</title>
        <meta name="description"
          content="Med Signator kan flera personer underteckna dokument digitalt, t ex årsredovisning, avtal etc, utan att behöva träffas då signeringen sker digitalt. 
    Tjänsten är det enklaste, snabbaste och tryggaste sättet att signera dokument. Ni kan med hjälp av BankID bli klara på några minuter, vart än ni 
    än befinner er." />

        <meta property="og:url" content="https://signator.se/guide" />
        <meta property="og:title" content="Digital signering av årsredovisning och avtal | Signator" />
        <meta property="og:description"
          content="Med Signator kan flera personer underteckna dokument digitalt, t ex årsredovisning, avtal etc, utan att behöva träffas då signeringen sker digitalt. 
    Tjänsten är det enklaste, snabbaste och tryggaste sättet att signera dokument. Ni kan med hjälp av BankID bli klara på några minuter, vart än ni 
    än befinner er." />
      </Helmet>
      <main>
        <MainHeader {...props} hideBack={true} />

        {/* <Alert /> */}
        {
          props.i18n.language === "en" ?
            <GuideContentEN /> : <GuideContentSE />
        }
        <MainFooter setToggleNavigation={setToggleNavigation} toggleNavigation={toggleNavigation} setToggleHelp={setToggleHelp} toggleHelp={toggleHelp} isGuide={true} />
      </main>
      <Footer setToggleNavigation={setToggleNavigation} toggleNavigation={toggleNavigation} setToggleHelp={setToggleHelp} toggleHelp={toggleHelp} />
      <Navigation toggleNavigation={toggleNavigation} />
    </div >
  );
}

export default withTranslation()(Guide);
