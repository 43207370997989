import React from "react";
import { Link } from "react-router-dom";
import { withTranslation} from "react-i18next";

function AboutContentSE(props) {
  const { t } = props;
  // console.log("props-->", props);
  return (
    <div className="main-content">
      <article>
        <h1>Om Signator</h1>
        <p>Signator är en e-signeringstjänst från Sveriges Mäklar- och Organisationstjänst AB (org.nr 559042-1631) och är byggt på avancerad, trygg och legitimerad teknologi, bl.a. BankID och elektronisk signeringsteknologi från Egreement AB.</p>
        <p>Tjänsten är skräddarsydd för effektiv användning i företag och organisationer som finns registrerade hos Bolagsverket. Alla registrerade styrelseledamöter samt revisor kan enkelt använda tjänsten.</p>
        <p>Med Signator kan alla ledamöter e-signera Årsredovisningen - och revisorn inkludera sin revisionsberättelse - enklare och snabbare än med någon annan e-tjänst.</p>
        <p>Signator kan även användas för att e-signera andra dokument, t.ex. styrelseprotokoll och avtal.</p>
        <p><br /> </p>
        <p className="button-group__centered">
          <Link to="/bli-kund-digital-arsredovisning" className="btn btn-primary">
            {t("usesignatornow")}!
        </Link>
        </p>
      </article>
    </div>
  );
}
export default withTranslation()(AboutContentSE);
