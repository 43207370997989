import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/main.scss";
import Footer from "./Footer";
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";
import Navigation from "./Navigation";
import apicall from "../apis/apicall";
import config from "../utils/config";
import helpers from "../utils/helpers";
import Spinner from "./Spinner";
import { withTranslation } from "react-i18next";

function Order1(props) {
  console.log(props);
  const [toggleNavigation, setToggleNavigation] = useState(false);
  const [toggleHelp, setToggleHelp] = useState(false);
  const { t } = props;
  const [orgNum, setorgNum] = useState("");
  const [loading, setLoading] = useState(false);
  const [policy, setpolicy] = useState(false);
  const [document_type, setdocument_type] = useState();

  // useEffect(() => {
  //   // config.LOCAL_FORAGE.clear().then(function () {
  //   //   console.log('Database is now empty.');
  //   // }).catch(function (err) {
  //   //   console.log(err);
  //   // });
  // }, []);

  useEffect(() => {
    clearLocalForaata();
  }, []);

  const clearLocalForaata = async () => {
    const data = await config.LOCAL_FORAGE.getItem("document_type").then(
      (resp) => {
        return resp;
      }
    );
    await config.LOCAL_FORAGE.clear()
      .then(function () {
        console.log("Database is now empty.");
      })
      .catch(function (err) {
        console.log(err);
      });
    await config.LOCAL_FORAGE.setItem("document_type", data);
  };

  const fetchdocument_type = async () => {
    await config.LOCAL_FORAGE.getItem("document_type").then((resp) => {
      setdocument_type(resp);
    });
  }

  const handleSubmit = (e) => {
    // const updateEmailsFlag=document_type==="other_document"?true:false
    setLoading(true);
    apicall.getCompany(orgNum).then((resp) => {
      // setLoading(false);
      if (resp) {
        const datetime = new Date();
        config.LOCAL_FORAGE.setItem("date-time", datetime);
        config.LOCAL_FORAGE.setItem("org-number", orgNum);
        config.LOCAL_FORAGE.setItem("company", resp.name);
        config.LOCAL_FORAGE.setItem("selected-members", [], (err) =>
          console.log(err)
        );
        config.LOCAL_FORAGE.setItem("board-members", resp.boardMembers, (err) =>
          setTimeout(() => {
            setLoading(false);
            props.history.push(`/members/${orgNum}`);
          }, 1000)
        );
      } else {
        setLoading(false);
        helpers.notify(t("msg_orgnrnotvalid"));
      }
    });
    e.preventDefault();
  };

  const formatOrgNumber = (value) => {
    fetchdocument_type();
    if (value.length > 6) {
      var res =
        value.substring(6, 7) !== "-"
          ? value.substring(0, 6) + "-" + value.substring(6, value.length)
          : value;
      setorgNum(res);
    } else setorgNum(value);
  };

  return (
    <div
      className={`container order order-orgnr ${
        toggleNavigation ? " has-navigation" : ""
      } ${toggleHelp ? " has-help" : ""} ${loading ? "has-spinner" : ""} `}
    >
      {/* <Alert /> */}
      <main>
        <MainHeader {...props} />
        <div className="app">
          <h1>{t("orgnr")}</h1>
          <p className="preamble">{t("order1_preamble")}</p>
          <form
            id="company-form"
            className="text-align__center"
            onSubmit={handleSubmit}
          >
            <label for="company-input">{t("organizationnumber")}</label>
            <input
              type="tel"
              name="random"
              placeholder="NNNNNN-NNNN"
              id="company-input"
              className="company-orgnr"
              maxLength="11"
              onChange={(e) => formatOrgNumber(e.target.value)}
              value={orgNum}
              required
            />
            <button
              className="btn-clear"
              type="reset"
              defaultValue="Reset"
              title={t("clearfield")}
              onClick={() => setorgNum("")}
            >
              {t("clearfield")}
            </button>
            <div className="company-name hide">Företagsnamn placeholder</div>
            <p className="button-group__centered form-group form-check form-termsandconditions">
              <label>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={policy}
                  onChange={(e) => setpolicy(e.target.checked)}
                ></input>
                Jag godkänner de{" "}
                <Link to="/allmanna-villkor/">Allmänna villkoren</Link> och
                bekräftar att jag tagit del av gällande{" "}
                <Link to="/integritetspolicy/">Integritetspolicy</Link>.
              </label>
            </p>
            {loading && <Spinner />}
            <button
              className="btn btn-success"
              disabled={`${
                orgNum.trim().length === 11 && !loading && policy === true
                  ? ""
                  : " disabled"
              }`}
            >
              {t("next")}
            </button>
          </form>
        </div>
        <MainFooter
          setToggleNavigation={setToggleNavigation}
          toggleNavigation={toggleNavigation}
          setToggleHelp={setToggleHelp}
          toggleHelp={toggleHelp}
        />
      </main>
      <aside>
        <p className="quote">
          “Som revisor för fler än 150 BRF:r kan jag intyga att Signator kommer
          som en hjälp från ovan.”
        </p>
        <p className="quoted">David Walman, Rävisor</p>
      </aside>

      <Navigation toggleNavigation={toggleNavigation} />
      <Footer
        setToggleNavigation={setToggleNavigation}
        toggleNavigation={toggleNavigation}
        setToggleHelp={setToggleHelp}
        toggleHelp={toggleHelp}
      />
    </div>
  );
}
export default withTranslation()(Order1);
