import React from "react";
import { withTranslation } from "react-i18next";

function MainHeader(props) {
  // console.log("props-->", props);
  const { t } = props;
  return (
    <div className="main-header">
      {props.hideBack === true ? (
        <div className="btn btn-link"></div>
      ) : (
        <button className="btn btn-link" onClick={() => props.history.goBack()}>
          <i className="icon icon-chevron-left"></i>
          {t("back")}
        </button>
      )}
      <a href="/" className="logo" title={t("home")}>
        {t("home")}
      </a>
      <div></div>
    </div>
  );
}
export default withTranslation()(MainHeader);
