import React, { useState } from "react";
import "../styles/main.scss";
import Header from "./Header";
import Footer from "./Footer";
import Navigation from "./Navigation";
import OverlaySpinner from "./OverlaySpinner";
import { withTranslation} from "react-i18next";

function SelectLanguage(props) {
  const { t } = props
  const [lang, setlang] = useState("sv")
  const [toggleNavigation, setToggleNavigation] = useState(false)
  const [toggleHelp, setToggleHelp] = useState(false)

  const handleLanguageChange = (event) => {
    let newlang = event.target.value;
    setlang(newlang)
    console.log("state value is", newlang, props.i18n.changeLanguage);
    props.i18n.changeLanguage(newlang);
  }

  return (
    <div className={`container articlepage language ${toggleNavigation ? " has-navigation" : ""} ${toggleHelp ? " has-help" : ""}`}>
      {/* Language selection start*/}
      <div className="language-select">
        <label for="lang">{t("language")}</label>
        <select
          className="icon-chevron-down"
          value={lang}
          name="lang"
          id="lang"
          onChange={handleLanguageChange}
        >
          <option disabled>{t("language")}</option>
          <option value="en">English</option>
          <option value="sv">Svenska</option>
        </select>
      </div>
      {/* Language selection end*/}
      <Header {...props} hideBack={true}/>
      <main>
          <h1>Select language</h1>
      </main>

      <Footer setToggleNavigation={setToggleNavigation} toggleNavigation={toggleNavigation} setToggleHelp={setToggleHelp} toggleHelp={toggleHelp} />
      <Navigation toggleNavigation={toggleNavigation} />
      <OverlaySpinner />
    </div>
  );
}

export default withTranslation()(SelectLanguage);

