import React from "react";
import { withTranslation } from "react-i18next";

function Spinner(props) {
    const { t } = props
    // console.log("props-->", props);
    return (
        <div className="css-spinner">{t("wait")}...</div>
    );
}
export default withTranslation()(Spinner);
