import React, { useState, useEffect } from "react";
import "../styles/main.scss";
import config from "../utils/config";
import _ from "lodash";
import Footer from "./Footer";
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";
import Navigation from "./Navigation";
import apicall from "../apis/apicall";
import Modal from "./UpdateModal";
import { withTranslation } from "react-i18next";
import helpers from "../utils/helpers";
import OverlaySpinner from "./OverlaySpinner";
import "../styles/pages/_accountant.scss";

function Accountant(props) {
  const [member, setmember] = useState();
  const [agreement, setagreement] = useState({});
  const [show, setshow] = useState(false);
  const uuid = props.match.params.uuid;
  const [toggleNavigation, setToggleNavigation] = useState(false);
  const [toggleHelp, setToggleHelp] = useState(false);
  const [fileUploadButton, setfileUploadButton] = useState(true);
  const [loading, setLoading] = useState(true);
  const [invoicePath, setinvoicePath] = useState();
  const [showInput, setshowInput] = useState(true);
  const { t } = props;
  const [signedDocument, setsignedDocument] = useState(null);
  const [revMemberLink, setrevMemberLink] = useState(null);

  const [document_type, setdocument_type] = useState();

  const fetchDocumentType = async () => {
    await config.LOCAL_FORAGE.getItem("document_type").then((resp) => {
      setdocument_type(resp);
    });
  };

  useEffect(() => {
    fetchDocumentType();
  });
  const handleClose = (x) => {
    console.log("came here");
    setshow(!show);
    setmember(x);
    if (!x) fetchAgreement();
  };

  const fetchAgreement = () => {
    setLoading(true);

  apicall.getOrderYearlyRev(uuid).then((resp) => {
      setagreement(resp.data);
      setLoading(false);
      if (!_.isEmpty(resp.data.orderYealyRevMembers)) {
        let orderYealyRevMembers = resp.data.orderYealyRevMembers;
        let revExMember = orderYealyRevMembers.find((x) => x.role === "REVEX" || x.role === "REV" || x.role === "REVT"||
         x.role === "REVH" || x.role === "REVL");
        let link = revExMember && revExMember.signingLink;
        setrevMemberLink(link);
      }
      if (!_.isEmpty(resp.data.orderYearlyAgreements)) {
        let link = resp.data.orderYearlyAgreements[0].signedDocument;
        setsignedDocument(link);
        if (resp.data.orderYearlyAgreements[1]) {
          let link2 = resp.data.orderYearlyAgreements[1].signedDocument
            ? resp.data.orderYearlyAgreements[1].signedDocument
            : resp.data.orderYearlyAgreements[1].uploadedDocument;
          setinvoicePath(link2);
          link2 && setshowInput(false);
        }
      }
    });
  };

  useEffect(() => {
    document.body.classList.add("accountant-body");
    fetchAgreement();
    // fetchBoardMembers();
  }, []);

  const onSelectFile = (e) => {
    setfileUploadButton(false);
    e.target.files && uploadDocument(e.target.files[0]);
  };

  const constructTable = () => {
    const members = agreement.orderYealyRevMembers;
    if (!_.isEmpty(members)) {
      const sortedMembers = _.sortBy(members, ["role"]);
      return sortedMembers.map((boardMember, index) => {
        let fname = boardMember.firstName ? boardMember.firstName : "";
        let lname = boardMember.lastName ? boardMember.lastName : "";
        return (
          <tr className="row-member" onClick={() => handleClose(boardMember)}>
           {document_type !== "other_document" && <td className="member-role">{boardMember.role}</td>}
            <td className="member-name">{`${fname} ${lname}`}</td>
            <td className="member-contactinfo">
              <div>
                {boardMember.email}, {boardMember.mobile}
              </div>
            </td>
            <td className="status">
              <div
                className={boardMember.agreementState}
                title={boardMember.agreementState}
              ></div>
            </td>
          </tr>
        );
      });
    }
  };

  const uploadDocument = (file) => {
    if (file) {
      setLoading(true);
      apicall.uploadAgreement(file, t).then((resp) => {
        console.log(resp);
        setLoading(false);
        setshowInput(false);
        if (resp.status === 200) {
          setinvoicePath(resp.data);
          config.LOCAL_FORAGE.setItem("invoice-path", resp.data, (err) =>
            console.log(err)
          );
          // helpers.notify(t("msg_documentuploaded"));
        }
      });
    }
  };

  const createRevAgreement = () => {
    console.log("createRevAgreement");
    setLoading(true);
    let rev = agreement.orderYealyRevMembers.filter(
      (x) => x.agreementState === null || _.isEmpty(x.agreementState)
    );
    console.log("REVE__",rev)
    if (document_type === "other_document") {
      apicall
      .createNewRevAgreement(signedDocument, invoicePath, rev, agreement.id, t)
      .then((resp) => {
        setLoading(false);
        console.log(resp);
        if (!_.isEmpty(resp.data)) {
          setagreement(resp.data);
          if (!_.isEmpty(resp.data.orderYealyRevMembers)) {
            let orderYealyRevMembers = resp.data.orderYealyRevMembers;
            let revExMember = orderYealyRevMembers.find(
              (x) => x.role === "REVEX"|| x.role === "REV" || x.role === "REVT" || x.role === "REVH" || x.role === "REVL"
            );
            let link = revExMember && revExMember.signingLink;
            setrevMemberLink(link);
          }
        }
      });
    }

   else { apicall
      .createRevAgreement(signedDocument, invoicePath, rev, agreement.id, t)
      .then((resp) => {
        setLoading(false);
        console.log(resp);
        if (!_.isEmpty(resp.data)) {
          setagreement(resp.data);
          if (!_.isEmpty(resp.data.orderYealyRevMembers)) {
            let orderYealyRevMembers = resp.data.orderYealyRevMembers;
            let revExMember = orderYealyRevMembers.find(
              (x) => x.role === "REVEX" || x.role === "REV" || x.role === "REVT" || x.role === "REVH" || x.role === "REVL"
            );
            let link = revExMember && revExMember.signingLink;
            setrevMemberLink(link);
          }
        }
      });}
  };

  console.log("rev", revMemberLink);
  return (
    <div
      className={`container detailview accountant ${
        loading ? "has-spinner has-overlay" : ""
      } ${toggleNavigation ? " has-navigation" : ""} ${
        toggleHelp ? " has-help" : ""
      } ${show && "has-overlay"}`}
    >
      {show ? (
        <main className={`${show ? "overlay" : ""}`}>
          <button className="btn btn-close" onClick={handleClose}>
            {t("close")}
          </button>{" "}
          <Modal
            show={show}
            handleClose={handleClose}
            member={member}
            className="translate-this"
            isAccountantModal={true}
          />
        </main>
      ) : (
        <main>
          <MainHeader {...props} />
          <div className="app">
            {loading && <OverlaySpinner />}
            <h1>{t("auditorpage")}</h1>
            <p>{t("auditorpage_text")}</p>
            <div className="form-group">
              <label>{t("organizationnumber")}</label>
              <p>
                {agreement.orgNumber}
                {/* {orgNumber} */}
              </p>
            </div>
            <div className="form-group">
              <label>{t("associationcompany")}</label>
              <p>{agreement.companyName}</p>
            </div>
            <div className="form-group document">
              <label>{t("document")} 1</label>
              <p className="ellipsis">
                {
                  // helpers.getFilename(signedDocument)
                  "Årsredovisning.pdf"
                }
              </p>
              <a
                href={helpers.encodePath(signedDocument)}
                target="_blank"
                className="icon icon-document-1"
                alt=""
                title={t("opendocument")}
                rel="noopener noreferrer"
              >
                {t("opendocument")}
              </a>
            </div>
            <div
              className={`${
                invoicePath
                  ? "form-group document"
                  : "form-group document disabled"
              } `}
            >
              <label>{t("document")} 2</label>
              <p className="ellipsis">
                <span className="not-uploaded">{t("waitingforupload")}</span>
                {/* {helpers.getFilename(invoicePath)} */}
                Revisionsrapport.pdf
              </p>
              <div
                className="icon icon-document-1 icon-grey not-uploaded"
                title={t("waitingforupload")}
              >
                {t("waitingforupload")}
              </div>
              <a
                href={helpers.encodePath(invoicePath)}
                target="_blank"
                className="icon icon-document-1 uploaded"
                title={t("opendocument")}
                rel="noopener noreferrer"
              >
                {t("opendocument")}
              </a>
            </div>
            <div>
              <table className="table-members-list">
                <thead>
                  <tr>
                  {document_type !== "other_document" && <th>{t("role")}</th>}
                    <th>{t("name")}</th>
                    <th>{t("contactinfo")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{constructTable()}</tbody>
              </table>
            </div>
            {showInput && (
              <div className="">
                <p className="text-align__center first-letter">
                  {t("upload")} {t("auditreport")} (pdf)
                </p>
                {fileUploadButton ? (
                  <button className="btn btn-add btn-add-primary">
                    {t("upload")} {t("auditreport")}
                  </button>
                ) : (
                  <div></div>
                )}
                <input
                  type="file"
                  name="upload"
                  accept="application/pdf"
                  onChange={onSelectFile}
                  // onBlur={uploadDocument}
                  // onChange={uploadDocument}
                />
              </div>
            )}
            {agreement.orderStatus === "REV_SIGNED" ? (
              ""
            ) : agreement.orderStatus !== "REV_INVITED" ? (
              <button
                disabled={invoicePath && !loading ? false : true}
                onClick={createRevAgreement}
                className="btn btn-success"
              >
                {t("next")}
              </button>
            ) : (
              <a href={revMemberLink}>
                <button
                  disabled={invoicePath ? false : true}
                  className="btn btn-success"
                >
                  {t("to_signing")}
                </button>
              </a>
            )}
          </div>
          <MainFooter
            setToggleNavigation={setToggleNavigation}
            toggleNavigation={toggleNavigation}
            setToggleHelp={setToggleHelp}
            toggleHelp={toggleHelp}
          />
        </main>
      )}
      <aside>
        <p>{`En årsredovisning signerad av ${
          agreement.companyName ? agreement.companyName : ""
        } styrelse via Signator.se är redo för hantering.`}</p>
        <p>Så här gör du:</p>
        <ol>
          <li>
            Kontrollera årsredovisningen genom att klicka på dokumentikonen till
            vänster.
          </li>
          <li>Ladda upp revisionsrapporten som en pdf-bilaga.</li>
          <li>Signera årsredovisningen och revisionsrapporten med BankID.</li>
        </ol>
        <p>Klart!</p>
      </aside>
      <Navigation toggleNavigation={toggleNavigation} />
      <Footer
        setToggleNavigation={setToggleNavigation}
        toggleNavigation={toggleNavigation}
        setToggleHelp={setToggleHelp}
        toggleHelp={toggleHelp}
      />
    </div>
  );
}
export default withTranslation()(Accountant);
