import React from "react";
import { withTranslation } from "react-i18next";

function GuideContentSE(props) {
  const { t } = props;
  // console.log("props-->", props);
  return (
    <div className="main-content">
      <h1>Använd Signator</h1>
      <ul className="steps steps-vertical">
        <li className="step01">
          <strong>Ange org.nr</strong>
          Börja med att fylla i organisationsnummer på din förening eller ditt
          företag
        </li>
        <li className="step02">
          <strong>Ange kontaktinformation</strong>
          Styrelsemedlemmarna visas. Ange e-post och mobilnummer till samtliga
          <ul>
            <li className="step0201">
              <strong>Lägg till ev. revisor</strong>
              Lägg till eventuell revisor och andra kontakter samt deras
              kontaktuppgifter
            </li>
          </ul>
        </li>
        <li className="step03">
          <strong>Ladda upp dokument (pdf)</strong>
          Ladda upp din årsredovisning eller annat dokument
        </li>
        <li className="step04">
          <strong>Avisera parterna</strong>
          Avisera ledamöterna att dokument finns klart för signering
        </li>
        <li className="step05">
          <strong>Granska och e-signera</strong>
          Använd BankID för att signera dokument
          <ul>
            <li className="step0501">
              <strong>Notifiera ev. revisor</strong>
              När alla signerat notifieras ev. revisor för uppladdning och
              signering av sin revisionsberättelse
            </li>
          </ul>
        </li>
        <li className="step06">
          <strong>Färdigt!</strong>
          När samtliga har signerat går det att ladda ned ett sammanställt
          dokument med e-signerad årsredovisning och ev. revisionsberättelse
        </li>
      </ul>
    </div>
  );
}
export default withTranslation()(GuideContentSE);
