import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: {
        // Select document type page 
        useSignator: "Use Signator",
        yearlyRevenue: "Yearly Revenue",
        otherDocument: "Other Document",

        //----------payment page start--------//
        agreement: "I accept the payment terms",
        annualReport: "Signing Annual Report",
        paperInvoice: "Paper Invoice",
        vat: "VAT",
        coupon: "Coupon",
        job_mail_msg: "Remember to use your private job email",
        enterEmail: "Please enter E-mail",
        invalidEmail: "Please enter valid E-mail",
        enterConfirmEmail: "Please enter Confirm E-mail",
        invalidConfEmail: "Confirm E-mail is wrong",
        enterInvEmail: "Please enter Invoice E-mail",
        enterOrgNr: "Please enter organization Number",
        enterCompany: "Please enter Company Name",
        enterFname: "Please enter First Name",
        enterLname: "Please enter Last Name",
        enterAddr: "Please enter Billing Address",
        enterCity: "Please enter City",
        enterZip: "Please enter Zip-Code",
        loading: "Loading Data...",
        fetchinCompany: "Fetching Company Data...",
        transactionMsg: "Please wait.. Don't Close the page Until the transaction is complete",
        orgDetails: "Organization Details",
        brokerDetails: "Broker Details",
        invoicingDetails: "Invoicing details",
        discount: "discount",
        orgInvalid: "Non valid organisation number",
        invoiceEmail: "Email address for invoicing",
        //----------payment page end --------//
        click_on_name_tosign: "Click on your name to sign:",
        language: "Language",
        home: "Home",
        start_heading: "Welcome!",
        start_preamble:
          "We help you get your annual report signed by board members and auditor via BankID!",

        about_heading: "This is how Signator works",
        about_steps_1_heading: "Upload document",
        about_steps_1_text:
          "Start by uploading your annual financial statement",
        about_steps_2_heading: "Send for signing",
        about_steps_2_text:
          "Send the document to the board members for signing and auditor for uploading of the audit report",
        about_steps_3_heading: "Sign document",
        about_steps_3_text: "Use BankID to sign the document",
        about_steps_4_heading: "Download the document",
        about_steps_4_text:
          "Once everyone has signed, it is possible to download a compiled document with signatures, annual report and audit report",
        btn_start: "Start",
        back: "Back",
        forward: "Forward",

        msg_orgnrnotvalid: "The organization number is invalid",
        deletemember: "Delete member",
        msg_deletemembersuccess: "The member was deleted",
        msg_deletememberfail: "Deleting the member failed",
        msg_agreementcreated: "Agreement created",
        msg_agreementcreationfail: "Agreement creation failed",
        msg_accountantagreementcreated: "Accountant agreement created",
        msg_accountantagreementcreationfail:
          "Accountant agreement creation failed",
        msg_reportcreated: "Report created",
        msg_reportcreationfail: "Report creation failed",
        msg_accountantreportcreated: "Accountant report created",
        msg_accountantreportcreationfail: "Accountant report creation failed",
        msg_documentuploaded: "Document uploaded",
        msg_documentuploadfail: "Document upload failed",
        msg_uploadfail: "Upload failed",
        msg_emailalreadyexists:
          "Email is already Registered, try with new email",
        msg_accountcreated: "Account created",

        loading: "Loading...",
        waitingforupload: "Waiting for upload",
        wait: "Wait",
        next: "Next",
        role: "Role",
        selectrole: "Select role",
        auditor: "Auditor",
        externalauditor: "External auditor",
        internalauditor: "Internal auditor",
        auditorpage: "Auditor page",
        auditorpage_text:
          "All members of the Board have signed the Annual Report. Please upload your audit report and sign with BankID.",

        name: "Name",
        getstarted: "Get started",
        clearfield: "Clear field",
        closeform: "Close form",
        yes: "Yes",
        no: "No",
        emailinvoice: "E-mail invoice",
        firstname: "First name",
        lastname: "Last name",
        associationcompany: "Association/company",
        emailaddress: "E-mail address",
        confirmEmail: "Confirm E-mail address",
        mobilenumber: "Mobile number",
        optional: "optional",
        contactinformation: "Contact information",
        billingaddress: "Billing address",
        zipcode: "Zip code",
        city: "City",
        contactinfo: "Contact info",
        pay: "Pay",
        invoice: "Invoice",
        updatemember: "Update member",
        savemember: "Save member",
        close: "Close",
        delete: "Delete",
        update: "Update",
        updatefailed: "Update failed",
        submit: "Submit",
        save: "Save",
        saved: "Saved",
        cancel: "Cancel",
        saveandinvite: " Save and send invite",
        editTitle: "Do you want to send a new invitation?",
        preview: "Preview",
        member: "Member",
        add: "Add",
        remove: "Remove",
        removemember: "Remove member",
        upload: "Upload",
        document: "Document",
        documentname: "Document name",
        opendocument: "Open document",
        auditreport: "Audit report",
        file: "File",
        filename: "File name",
        status: "Status",
        edit: "Edit",
        help: "Help",
        gethelp: "Get help",
        boardmembers: "Members of the Board",
        sign: "Sign",
        complete: "Complete",
        send_for_signing: "Send for signing",
        to_signing: "To signing",
        signaturestatus: "Signaturestatus",

        order1_preamble: "Please enter the organization number of the association/company",
        organizationnumber: "Organization number",
        orgnr: "Org.nr",

        usesignatornow: "Use Signator now",
        guide: "Guide",
        userguide: "User guide",

        boardmembers_text: "Do you have an accountant and / or internal auditor who should receive the document when signed by the board?",

        updatemember_text: "We did not find any contact information on the people below. Please enter their email and phone number in the fields below.",
        email_update_success: "Email Updated",
        email_update_fail: "email update failed",
        mobile_update_success: "Mobile number Updated",
        mobile_update_fail: "Mobile number Update Failed",
        createuser_contactinfo: "Provide contact information to the auditor, alternate auditor and / or internal auditor",
        createuser_addadditionalcontacts: "Add more people who should sign",
        complete_contactinfo_text: "We did not find any contact information on the people below. Please enter their email and phone number in the fields below.",
        review: "Review",
        reviewdetails: "Review details",
        signlink_note: "To sign, click on your name in the list above and select &quot;To sign&quot;",

        select_who_should_sign: "Select persons who should sign",
        boardmembers_not_present: "We did not find any board members connected to this organization number. Please try again.",
        reverror: "You need to add an external accountant",

        // Article pages
        contact_heading: "Contact heading",
        contact_text: "<p>Lorem ipsum</p>", // Html test

        faq_heading: "FAQ heading",
        faq_text: "<p>Lorem ipsum</p>", // Html test

        guide_heading: "FAQ heading",
        guide_text: "<p>Lorem ipsum</p>", // Html test

        help_heading: "Help heading",
        help_text: "<p>Lorem ipsum</p>", // Html test

        privacy_heading: "Privacy heading",
        privacy_text: "<p>Lorem ipsum</p>", // Html test

        services_heading: "Services heading",
        services_text: "<p>Lorem ipsum</p>", // Html test
        signed_document:"Signed Document"
      },
    },
    sv: {
      translations: {
        // Select document type page 
        useSignator: "Använd Signator",
        yearlyRevenue: "Årsredovisning",
        otherDocument: "Annat dokument",

        //----------payment page start--------//
        agreement: "Jag godkänner betalningsvillkoren",
        annualReport: "Signering Årsredovisning",
        otherDocument_annualReport: "Signering dokument",
        paperInvoice: "Pappersfaktura",
        vat: "Moms",
        coupon: "Rabatt",
        enterEmail: "Fyll i en personlig e-postadress",
        invalidEmail: "Ni måste uppge en gilltig e-postadress",
        enterConfirmEmail: "Bekräfta e-postadress",
        invalidConfEmail: "E-postadressen är fel",
        enterInvEmail: "Fyll i e-postadress för faktura ",
        enterOrgNr: "Fyll i ett organisationsnummer",
        enterCompany: "Fyll i ett företagsnamn",
        enterFname: "Fyll i ditt förnamn",
        enterLname: "Fyll i ditt efternamn",
        enterAddr: "Fyll i fakturaadress",
        enterCity: "Fyll i stad",
        enterZip: "Fyll i postnummer",
        loading: "Hämtar information...",
        fetchinCompany: "Skickar information...",
        transactionMsg: "Var vänlig och vänta. Stäng inte sidan förrän processen är klar.",
        orgDetails: "Företagsuppgifter",
        brokerDetails: "Användaruppgifter",
        invoicingDetails: "Faktureringsuppgifter",
        discount: "rabatt",
        orgInvalid: "Ogiltigt organisationsnummer",
        invoiceEmail: "E-postadress för fakturering",
        //----------payment page end --------//

        click_on_name_tosign: "Klicka på ditt namn för att signera:",
        job_mail_msg: "Tänk på att använda er personliga e-postadress",
        home: "Hem",
        language: "Språk",
        start_heading: "Välkommen!",
        start_preamble:
          "Vi hjälper dig att få din årsredovisning signerad av styrelsemedlemmar och revisor via BankID!",
        about_heading: "Så här fungerar Signator",
        about_steps_1_heading: "Ladda upp dokument",
        about_steps_1_text: "Börja med att ladda upp din årsredovisning",
        about_steps_2_heading: "Skicka för signering",
        about_steps_2_text:
          "Skicka dokumentet för signering till styrelsemedlemmar och revisor för uppladdning av revisionsberättelse",
        about_steps_3_heading: "Signera dokument",
        about_steps_3_text: "Använd BankID för att signera dokument",
        about_steps_4_heading: "Spara ned dokumentet",
        about_steps_4_text:
          "När samtliga har signerat går det att ladda ned ett sammanställt dokument med signaturer, årsredovisning och revisionsberättelse",
        btn_start: "Starta",
        back: "Bakåt",
        forward: "Framåt",

        msg_orgnrnotvalid: "Organisationsnumret är ogiltigt",
        deletemember: "Ta bort medlem",
        msg_deletemembersuccess: "Medlemmen raderades",
        msg_deletememberfail: "Radering av medlemmen misslyckades",
        msg_agreementcreated: "Avtal skapades",
        msg_agreementcreationfail: "Skapande av avtal misslyckades",
        msg_accountantagreementcreated: "Revisoravtalet skapades",
        msg_accountantagreementcreationfail:
          "Skapande av revisoravtal misslyckades",
        msg_reportcreated: "Rapport skapades",
        msg_reportcreationfail: "Skapande av rapport misslyckades",
        msg_accountantreportcreated: "Revisorsrapport skapades",
        msg_accountantreportcreationfail:
          "Skapande av revisorsrapport misslyckades",
        msg_documentuploaded: "Dokumentet laddades upp",
        msg_documentuploadfail: "Uppladdning av dokumentet misslyckades",
        msg_uploadfail: "Uppladdning misslyckades",
        msg_emailalreadyexists: "E-postadressen finns redan, använd en annan",
        msg_accountcreated: "Konto skapades",

        loading: "Laddar...",
        waitingforupload: "Väntar på uppladdning",
        wait: "Vänta",
        next: "Nästa",
        role: "Roll",
        selectrole: "Välj roll",
        auditor: "Revisor",
        externalauditor: "Extern revisor",
        internalauditor: "Intern revisor",
        auditorpage: "Revisorsida",
        auditorpage_text:
          "Samtliga ledamöter i styrelsen har signerat Årsredovisningen. Vänligen ladda upp din revisionsberättelse och signera med BankID.",

        name: "Namn",
        getstarted: "Kom igång",
        clearfield: "Rensa fält",
        closeform: "Stäng formulär",
        yes: "Ja",
        no: "Nej",
        emailinvoice: "E-postfaktura",
        firstname: "Förnamn",
        lastname: "Efternamn",
        associationcompany: "Förening/företag",
        emailaddress: "E-postadress",
        confirmEmail: "Bekräfta e-postadressen",
        billingaddress: "Faktureringsadress",
        zipcode: "Postnummer",
        city: "Ort",
        mobilenumber: "Mobilnummer",
        optional: "frivilligt",
        contactinformation: "Kontaktinformation",
        contactinfo: "Kontaktinfo",
        pay: "Betala",
        invoice: "Faktura",
        updatemember: "Uppdatera medlem",
        savemember: "Spara medlem",
        close: "Stäng",
        delete: "Radera",
        update: "Uppdatera",
        updatefailed: "Uppdatering misslyckades",
        submit: "Skicka",
        save: "Spara",
        saved: "Sparad",
        saveandinvite: "Spara och skicka inbjudan",
        editTitle: "Vill du skicka en ny inbjudan?",
        cancel: "Avbryt",
        preview: "Förhandsvisa",
        member: "Medlem",
        add: "Lägg till",
        remove: "Ta bort",
        removemember: "Ta bort medlem",
        upload: "Ladda upp",
        document: "Dokument",
        documentname: "Dokumentnamn",
        opendocument: "Öppna dokument",
        auditreport: "Revisionsberättelse",
        file: "Fil",
        filename: "Filnamn",
        status: "Status",
        edit: "Redigera",
        help: "Hjälp",
        gethelp: "Få hjälp",
        boardmembers: "Styrelsemedlemmar",
        sign: "Signera",
        complete: "Komplettera",
        send_for_signing: "Skicka för signering",
        to_signing: "Till signering",
        signaturestatus: "Signaturstatus",

        order1_preamble: "Vänligen fyll i organisationsnummer på föreningen/företaget",
        organizationnumber: "Organisationsnummer",
        orgnr: "Org.nr",

        usesignatornow: "Använd Signator nu",
        guide: "Guide",
        userguide: "Användarguide",
        
        boardmembers_text: "Har ni en revisor och/eller intern revisor som ska motta dokumentet när det signerats av styrelsen?",

        updatemember_text: "Vi hittade inga kontaktuppgifter på personerna nedan. Vänligen ange deras email och telefonnummer i fälten nedanför.",
        email_update_success: "e-post uppdaterad",
        email_update_fail: "e-postuppdatering misslyckades",
        mobile_update_success: "Mobilnummer uppdaterad",
        mobile_update_fail: "Mobilnummer misslyckades",
        createuser_contactinfo: "Ange kontaktinformation till revisor, revisorssuppleant och/eller internrevisor",
        createuser_addadditionalcontacts: "Lägg till fler personer som skall signera",
        complete_contactinfo_text: "Vi hittade inga kontaktuppgifter på personerna nedan. Vänligen ange deras email och telefonnummer i fälten nedanför.",
        review: "Granska",
        reviewdetails: "Granska detaljer",
        signlink_note: "För att signera klicka på ditt namn i listan ovan och välj &quot;Till signering&quot;",

        select_who_should_sign: "Välj vilka personer som skall signera",
        boardmembers_not_present: "Vi kan inte hitta några styrelsemedlemmar till det angivna organisationsnumret. Var god kontrollera numret och försök igen.",
        reverror: "Du behöver lägga till en extern revisor",

        // Article pages
        contact_heading: "Kontakt rubrik",
        contact_text: "<p>Lorem ipsum</p>", // Html test

        faq_heading: "FAQ rubrik",
        faq_text: "<p>Lorem ipsum</p>", // Html test

        guide_heading: "FAQ rubrik",
        guide_text: "<p>Lorem ipsum</p>", // Html test

        help_heading: "Hjälp rubrik",
        help_text: "<p>Lorem ipsum</p>", // Html test

        privacy_heading: "Privacy rubrik",
        privacy_text: "<p>Lorem ipsum</p>", // Html test

        services_heading: "Services rubrik",
        services_text: "<p>Lorem ipsum</p>", // Html test
        signed_document:"Signerat dokument"
      },
    },
  },
  fallbackLng: "sv",
  // debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },

  react: {
    wait: true,
  },
});

export default i18n;
