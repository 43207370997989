import React, { useState } from "react";
import "../styles/main.scss";
import Footer from "./Footer";
import MainFooter from "./MainFooter";
import Navigation from "./Navigation";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

function TermsAndConditions(props) {
    const { t } = props
    const [toggleNavigation, setToggleNavigation] = useState(false)
    const [toggleHelp, setToggleHelp] = useState(false)
    return (
        <div className={`container articlepage termsandconditions ${toggleNavigation ? " has-navigation" : ""} ${toggleHelp ? " has-help" : ""}`}>
            {/* <Alert /> */}
            <main>
                <div className="main-content">
                    <article>
                        <h1>Allm&auml;nna villkor</h1>
                        <h2>1. Tj&auml;nsten</h2>
                        <p>Tj&auml;nsten har som uppgift att underl&auml;tta elektroniska signeringar.</p>
                        <p>Tj&auml;nsten tillhandah&aring;lls av Sveriges M&auml;klar- och Organisationstj&auml;nst AB (559042-1631).</p>
                        <h2>2. Pris</h2>
                        <p>Enligt r&aring;dande prislista.</p>
                        <h2>3. Betalningsvillkor</h2>
                        <p>Betalning sker i f&ouml;rskott i form av kortbetalning eller mot faktura.</p>
                        <h2>4.Underh&aring;ll</h2>
                        <p>Sveriges M&auml;klar- och Organisationstj&auml;nst AB f&ouml;rbeh&aring;ller sig r&auml;tten att underh&aring;lla och d&auml;rmed modifiera inneh&aring;llet i Tj&auml;nsten. Vidareutveckling och uppdatering av samtliga Sveriges M&auml;klar- och Organisationstj&auml;nst AB Tj&auml;nster sker fortl&ouml;pande.</p>
                        <h2>5. Ansvar</h2>
                        <p>Avtalspart mot kund &auml;r Sveriges M&auml;klar- och Organisationstj&auml;nst AB, som ansvarar f&ouml;r att m&ouml;jligg&ouml;ra elektroniska signeringar via www.signator.se.&nbsp;</p>
                        <h2>6. Support</h2>
                        <p>Sveriges M&auml;klar- och Organisationstj&auml;nst AB tillhandah&aring;ller support p&aring; kontortid. Helger har vi st&auml;ngt. Med support avses fr&auml;mst felanm&auml;lan och tekniska problem med Tj&auml;nsten.</p>
                        <h2>7. Reklamation</h2>
                        <p>Kund har r&auml;tt att reklamera Tj&auml;nsten om felanm&auml;lan inte kan avhj&auml;lpa problemet.&nbsp;&nbsp;</p>
                        <h2>8. Till&auml;mplig lag och tvist</h2>
                        <p>Avtal och Allm&auml;nna villkor skall tolkas och till&auml;mpas enligt svensk r&auml;tt. Tvist med anledning h&auml;rom skall slutligen avg&ouml;ras genom allm&auml;n domstol i Stockholms Tingsr&auml;tt som f&ouml;rsta instans.</p>
                        <h2>9 Force majeure</h2>
                        <p>Parterna &auml;r befriade fr&aring;n ansvar g&auml;llande brott mot Avtalet orsakat av omst&auml;ndigheter utanf&ouml;r Parts kontroll, s&aring;som st&ouml;rningar eller avbrott i publika data- eller telen&auml;t, olycksh&auml;ndelse, brand, &aring;sknedslag, explosion, krig, upplopp, &ouml;versv&auml;mning, naturkatastrof, arbetskonflikt (oavsett om den omfattar parts personal eller ej) eller &auml;ndrad myndighetsbest&auml;mmelse eller myndighets ingripande. F&ouml;r att s&aring;dan omst&auml;ndighet skall f&aring; &aring;beropas som befrielsegrund skall part underr&auml;tta den andre parten utan dr&ouml;jsm&aring;l. Parts &aring;liggande enligt Avtal &aring;terintr&auml;der s&aring; fort s&aring;dan omst&auml;ndighet som n&auml;mnts ej l&auml;ngre f&ouml;religger.</p>        </article>
                    <p><br /> </p>
                    <p className="button-group__centered">
                        <Link to="/bli-kund-digital-arsredovisning" className="btn btn-primary">
                            {t("usesignatornow")}!
                    </Link>
                    </p>
                </div>
                <MainFooter setToggleNavigation={setToggleNavigation} toggleNavigation={toggleNavigation} setToggleHelp={setToggleHelp} toggleHelp={toggleHelp} />
            </main>
            <Navigation toggleNavigation={toggleNavigation} />
            <Footer setToggleNavigation={setToggleNavigation} toggleNavigation={toggleNavigation} setToggleHelp={setToggleHelp} toggleHelp={toggleHelp} />
        </div>
    );
}
export default withTranslation()(TermsAndConditions);
