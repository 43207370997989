import React, { useState } from "react";
import "../styles/main.scss";
import Header from "./Header";
import StartContentSE from "./StartContentSE";
import StartContentEN from "./StartContentEN";
import Footer from "./Footer";
import MainFooter from "./MainFooter";
import Navigation from "./Navigation";
import OverlaySpinner from "./OverlaySpinner";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

function Start(props) {
  const [toggleNavigation, setToggleNavigation] = useState(false)
  const [toggleHelp, setToggleHelp] = useState(false)

  return (
    <div className={`container articlepage start ${toggleNavigation ? " has-navigation" : ""} ${toggleHelp ? " has-help" : ""}`}>
      <Helmet>
        <title>Digital signering av årsredovisning och avtal | Signator</title>
        <meta name="description"
          content="Med Signator kan flera personer underteckna dokument digitalt, t ex årsredovisning, avtal etc, utan att behöva träffas då signeringen sker digitalt. 
    Tjänsten är det enklaste, snabbaste och tryggaste sättet att signera dokument. Ni kan med hjälp av BankID bli klara på några minuter, vart än ni 
    än befinner er." />

        <meta property="og:url" content="https://signator.se/" />
        <meta property="og:title" content="Digital signering av årsredovisning och avtal | Signator" />
        <meta property="og:description"
          content="Med Signator kan flera personer underteckna dokument digitalt, t ex årsredovisning, avtal etc, utan att behöva träffas då signeringen sker digitalt. 
    Tjänsten är det enklaste, snabbaste och tryggaste sättet att signera dokument. Ni kan med hjälp av BankID bli klara på några minuter, vart än ni 
    än befinner er." />
      </Helmet>
      <Header {...props} hideBack={true} />
      <main>
        {
          props.i18n.language === "en" ?
            <StartContentEN {...props} /> : <StartContentSE {...props} />
        }
        <MainFooter setToggleNavigation={setToggleNavigation} toggleNavigation={toggleNavigation} setToggleHelp={setToggleHelp} toggleHelp={toggleHelp} />
      </main>
      <Footer setToggleNavigation={setToggleNavigation} toggleNavigation={toggleNavigation} setToggleHelp={setToggleHelp} toggleHelp={toggleHelp} />
      <OverlaySpinner />
      <Navigation toggleNavigation={toggleNavigation} />
      <div className="overlay overlay-help">
        <main>
          <h2>Start hjälp</h2>
          <p>Celery quandong swiss chard chicory earthnut pea potato. Salsify taro catsear garlic gram celery bitterleaf wattle seed collard greens nori. Grape wattle seed kombu beetroot horseradish carrot squash brussels sprout chard. </p>
          <p>Pea horseradish azuki bean lettuce avocado asparagus okra. Kohlrabi radish okra azuki bean corn fava bean mustard tigernut jícama green bean celtuce collard greens avocado quandong fennel gumbo black-eyed pea. Grape silver beet watercress potato tigernut corn groundnut. Chickweed okra pea winter purslane coriander yarrow sweet pepper radish garlic brussels sprout groundnut summer purslane earthnut pea tomato spring onion azuki bean gourd. Gumbo kakadu plum komatsuna black-eyed pea green bean zucchini gourd winter purslane silver beet rock melon radish asparagus spinach. </p>
          <p>Beetroot water spinach okra water chestnut ricebean pea catsear courgette summer purslane. Water spinach arugula pea tatsoi aubergine spring onion bush tomato kale radicchio turnip chicory salsify pea sprouts fava bean. Dandelion zucchini burdock yarrow chickpea dandelion sorrel courgette turnip greens tigernut soybean radish artichoke wattle seed endive groundnut broccoli arugula. </p>
        </main>
      </div>
    </div>
  );
}

export default withTranslation()(Start);

