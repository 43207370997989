import React from "react";
import { withTranslation} from "react-i18next";

function Header(props) {
  const { t } = props;
  return (
    <header>
      <button className="btn btn-link btn-back" onClick={() => props.history.goBack()}>
        <i className="icon icon-chevron-left"></i>
        {t("back")}
      </button>
      <a href="/" className="logo" title={t("home")}>
        {t("home")}
      </a>
      <div></div>
    </header>
  );
}
export default withTranslation()(Header);
