import React, { useEffect } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
 } from "react-router-dom";
import Start from "./components/Start";
import About from "./components/About";
import Services from "./components/ServicesAndPrices";
import Guide from "./components/Guide";
import Help from "./components/Help";
import FAQ from "./components/FAQ";
import PageNotFound from "./components/PageNotFound";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import Contact from "./components/Contact";
import Order1EnterOrgnr from "./components/Order1EnterOrgnr";
import PrivateRoute from "./helper_components/PrivateRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BoardMembers from "./components/BoardMembers";
import CreateAccountant from "./components/CreateAccountant";
import UpdateMember from "./components/UpdateMember";
import UploadDocument from "./components/UploadDocument";
import DetailedView from "./components/DetailView";
import Summary from "./components/Summary";
import Accountant from "./components/Accountant";
import PaymentInvoice from "./components/PaymentInvoice";
import PreviewDocument from "./components/PreviewDocument";
import config from "./utils/config";
import ShowBoardMember from "./components/ShowBoardMember";
import DeleteAccountant from "./components/DeleteAccountant";
import SelectLanguage from "./components/SelectLanguage";
import Broker from "./components/Broker";
import SelectDocsType from "./components/SelectDocsType";
import SmoothBoardMembers from "./components/SmoothBoardMembers";
function App() {
  useEffect(() => {
    config.LOCAL_FORAGE.getItem("date-time")
      .then(function (date) {
        const diffTime = Math.abs(date - new Date());
        console.log(Math.round(((diffTime % 86400000) % 3600000) / 60000));
        if (Math.round(((diffTime % 86400000) % 3600000) / 60000) > 30) {
          config.LOCAL_FORAGE.clear()
            .then(function () {
              console.log("Database is now empty.");
            })
            .catch(function (err) {
              console.log(err);
            });
        }
      })
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
  }, []);

  const RouteComponent = ({ component: Component, ...props }) => {
    return (
      <PrivateRoute
        {...props}
        component={(routeProps) => (
          <React.Fragment>
            <Component {...routeProps} {...props} />
          </React.Fragment>
        )}
      />
    );
  };

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Start} />
        <Route exact path="/om-signator" component={About} />
        <Route exact path="/tjanster-och-priser" component={Services} />
        <Route exact path="/guide" component={Guide} />
        <Route exact path="/hjalp" component={Help} />
        <Route exact path="/vanliga-fragor" component={FAQ} />
        <Route exact path="/integritetspolicy" component={PrivacyPolicy} />
        <Route exact path="/allmanna-villkor" component={TermsAndConditions} />
        <Route exact path="/kontakt" component={Contact} />
        <Route exact path="/bli-kund-digital-arsredovisning" component={Order1EnterOrgnr} />
        <Route exact path="/language" component={SelectLanguage} />
        <Route exact path="/betala-faktura" component={PaymentInvoice} />
        <Route exact path="/betala-faktura-epostadress" component={Broker} />
        <Route exact path="/valj-dokument-typ" component={SelectDocsType}/>
        <RouteComponent
          exact
          path="/members/:orgNumber"
          component={BoardMembers}
        />
         <RouteComponent
          exact
          path="/members/:orgNumber/documentId/:documentId/broker/:person_number"
          component={SmoothBoardMembers}
        />
        <RouteComponent
          exact
          path="/add-user/:orgNumber"
          component={CreateAccountant}
        />
        <RouteComponent
          exact
          path="/update/:orgNumber"
          component={UpdateMember}
        />
        <RouteComponent
          exact
          path="/upload/:orgNumber"
          component={UploadDocument}
        />
        <RouteComponent
          exact
          path="/detail/:orgNumber"
          component={DetailedView}
        />
        <Route
          exact
          path="/summary/:orgNumber/:agreementid"
          component={Summary}
        />
        <RouteComponent
          exact
          path="/showMember/:memberId"
          component={ShowBoardMember}
        />
        <RouteComponent
          exact
          path="/deleteAccountant/:memberId"
          component={DeleteAccountant}
        />
        <Route exact path="/accountant/:uuid" component={Accountant} />
        <Route path="/preview/:path" component={PreviewDocument} />
        <Route component={PageNotFound} />
      </Switch>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
