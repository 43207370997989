import React from 'react'
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

function MainFooter(props) {
    const { t } = props
    return (
        <div className="main-footer">
            <nav>

                {
                    props.hasOwnProperty('toggleNavigation') ?
                        <button onClick={() => props.setToggleNavigation(!props.toggleNavigation)} className="btn btn-navigation">Navigation</button>
                        : <button className="btn btn-navigation">Navigation</button>
                }
                <Link to={`${props.isGuide ? "/valj-dokument-typ" : "/bli-kund-digital-arsredovisning"}`} className="btn btn-primary hide">
                    {t("usesignatornow")}!
                </Link>
                <Link to="/valj-dokument-typ" className="btn btn-primary">
                    {t("usesignatornow")}!
                </Link>
                {
                    props.hasOwnProperty('toggleHelp') ?
                        <button onClick={() => props.setToggleHelp(!props.toggleHelp)} className="btn btn-help">
                            {t("gethelp")}
                        </button>
                        : <button className="btn btn-help">
                            {t("gethelp")}
                        </button>
                }
            </nav>
        </div>
    )
}
export default withTranslation()(MainFooter);
