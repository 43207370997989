import React, { useState } from "react";
import "../styles/main.scss";
import Header from "./Header";
import Footer from "./Footer";
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";
import Navigation from "./Navigation";
import { Link } from "react-router-dom";
import { withTranslation} from "react-i18next";

function PrivacyPolicy(props) {
  console.log(props);
  const { t } = props
  const [toggleNavigation, setToggleNavigation] = useState(false)
  const [toggleHelp, setToggleHelp] = useState(false)

 
  return (
    <div className={`container articlepage privacypolicy ${toggleNavigation ? " has-navigation" : ""} ${toggleHelp ? " has-help" : ""}`}>
      {/* <Alert /> */}
      <Header {...props} />
      <main>
        <MainHeader />
        <div className="main-content">
          <article>
            <h1>Integritetspolicy</h1>
            <p>Signator &auml;r en e-signeringstj&auml;nst fr&aring;n Sveriges M&auml;klar- och Organisationstj&auml;nst AB (559042-1631).</p>
            <p>Denna policy g&auml;ller f&ouml;r de b&aring;da bolagen och utg&aring;r fr&aring;n g&auml;llande dataskyddslagstiftning och f&ouml;rklarar varf&ouml;r vi inh&auml;mtar personuppgifter, vilka typer av uppgifter vi inh&auml;mtar, samt hur vi inh&auml;mtar, anv&auml;nder, och lagrar dina personuppgifter s&aring; att du kan k&auml;nna dig s&auml;ker p&aring; att alla uppgifter vi har om dig &auml;r i s&auml;kert f&ouml;rvar. Du har &auml;ven r&auml;tt att f&aring; veta under vilka f&ouml;ruts&auml;ttningar samt hur du kan ta tillvara dina r&auml;ttigheter.</p>
            <h2>Bakgrund</h2>
            <p>Vi behandlar dina personuppgifter fr&auml;mst f&ouml;r att fullf&ouml;lja v&aring;ra avtalsenliga f&ouml;rpliktelser, dvs f&ouml;r att tj&auml;nsten ska fungera som avsett. V&aring;r utg&aring;ngspunkt &auml;r att inte behandla fler personuppgifter &auml;n vad som beh&ouml;vs f&ouml;r &auml;ndam&aring;let. Vi beh&ouml;ver &auml;ven dina personuppgifter f&ouml;r att skicka dig viktig information.&nbsp;</p>
            <p>Du har r&auml;tt att mots&auml;tta dig hur vi anv&auml;nder dina personuppgifter och kan n&auml;rsomhelst kr&auml;va att vi raderar dina uppgifter.&nbsp;</p>
            <h2>Vilka personuppgifter behandlar vi?</h2>
            <p>Vi behandlar endast personuppgifter f&ouml;r att fullg&ouml;ra f&ouml;rpliktelser enligt avtal och g&auml;llande lag och n&auml;r informationen kan beh&ouml;vas f&ouml;r tj&auml;nstens verkst&auml;llande. Exempel p&aring; personuppgifter som vi behandlar &auml;r namn, personnummer, e-postadress och mobilnummer.&nbsp;</p>
            <p>Vi erh&aring;ller personuppgifter om dig som styrelseledamot via offentliga register hos Bolagsverket samt genom uppgifter som du, annan styrelseledamot, revisor, avtalspart, ekonomisk f&ouml;rvaltare, eller annan akt&ouml;r som av ovan n&auml;mnd part f&aring;tt access till dokument f&ouml;r uppladdning och signering, har registrerat i tj&auml;nsten.</p>
            <p>Dina personuppgifter raderas i tj&auml;nsten n&auml;r du som &auml;r ledamot tr&auml;der ur f&ouml;reningens styrelse och detta rapporterats till Bolagsverket. Personuppgifter vill dock &auml;ven i eftertid kunna finnas lagrade p&aring; e-signerade dokument, i tryggt f&ouml;rvar i tj&auml;nsten, i den m&aring;n dokumentet inte borttages av avtalspart.</p>
            <h2>Samtycke</h2>
            <p>Som styrelseledamot finns du med i offentliga register, vi f&ouml;rs&ouml;ker dock i m&ouml;jligaste m&aring;n inh&auml;mta &auml;ven ditt personliga samtycke innan vi b&ouml;rjar behandla dina personuppgifter. Du ger ditt samtycke f&ouml;rsta g&aring;ngen du anv&auml;nder Signator.</p>
            <p>Du har n&auml;r som helst r&auml;tt att &aring;terkalla ditt samtycke. Vi kommer d&aring; inte l&auml;ngre att behandla dina personuppgifter eller inh&auml;mta nya, under f&ouml;ruts&auml;ttning att det inte beh&ouml;vs f&ouml;r att fullg&ouml;ra dina eller v&aring;ra skyldigheter enligt avtal eller lag. T&auml;nk p&aring; att &aring;terkallelse av samtycke kan inneb&auml;ra att vi inte kan fullg&ouml;ra de skyldigheter vi har i f&ouml;rh&aring;llande till dig.</p>
            <h2>Dina personuppgifter behandlas p&aring; ett betryggande s&auml;tt</h2>
            <p>V&aring;ra rutiner och arbetss&auml;tt &auml;r utarbetade f&ouml;r att dina personuppgifter ska hanteras s&auml;kert. Utg&aring;ngspunkten &auml;r att endast arbetstagare och andra personer inom organisationen som beh&ouml;ver personuppgifterna f&ouml;r att utf&ouml;ra sina arbetsuppgifter ska ha tillg&aring;ng till dem.</p>
            <p>Beroende p&aring; vilka kontaktuppgifter vi har till dig f&ouml;rmedlas information med teknik som anses l&auml;mpligt f&ouml;r &auml;ndam&aring;let, t.ex. e-post, sms, telefon och api.</p>
            <p>V&aring;ra s&auml;kerhetssystem &auml;r utvecklade med din integritet i fokus och skyddar mot intr&aring;ng, f&ouml;rst&ouml;ring samt andra f&ouml;r&auml;ndringar som kan inneb&auml;ra en risk f&ouml;r din integritet.</p>
            <p>Vi har s&auml;rskilda policyer f&ouml;r IT-s&auml;kerhet f&ouml;r att s&auml;kerst&auml;lla att dina personuppgifter behandlas s&auml;kert.</p>
            <p>Vi &ouml;verf&ouml;r inte personuppgifter i andra fall &auml;n de som uttryckligen anges i denna policy.</p>
            <h2>N&auml;r l&auml;mnar vi ut dina personuppgifter?</h2>
            <p>V&aring;r utg&aring;ngspunkt &auml;r att inte l&auml;mna ut dina personuppgifter till tredje part om du inte har samtyckt till det eller om det inte &auml;r n&ouml;dv&auml;ndigt f&ouml;r att uppfylla v&aring;ra f&ouml;rpliktelser enligt avtal eller lag. I de fall vi l&auml;mnar ut personuppgifter till tredje part uppr&auml;ttar vi sekretessavtal samt s&auml;kerst&auml;ller att personuppgifterna behandlas p&aring; ett betryggande s&auml;tt.</p>
            <h2>Kontaktuppgifter&nbsp;</h2>
            <p>Har du fr&aring;gor om tj&auml;nsterna och v&aring;r hantering av dina personuppgifter &auml;r du v&auml;lkommen att kontakta oss p&aring;: <a href="mailto:support@smoot.se">support@smoot.se</a>&nbsp;samt <a href="mailto:support@signator.se">support@signator.se</a>.</p>
            <p>&nbsp;</p>
            <p>Denna policy &auml;r beslutad av Sveriges M&auml;klar- och Organisationstj&auml;nst AB 2020-04-25.</p>
            <p><br /> </p>
            <p className="button-group__centered">
              <Link to="/bli-kund-digital-arsredovisning" className="btn btn-primary">
                {t("usesignatornow")}!
            </Link>
            </p>
          </article>
        </div>
        <MainFooter setToggleNavigation={setToggleNavigation} toggleNavigation={toggleNavigation} setToggleHelp={setToggleHelp} toggleHelp={toggleHelp} />
      </main>
      <Navigation toggleNavigation={toggleNavigation} />
      <Footer setToggleNavigation={setToggleNavigation} toggleNavigation={toggleNavigation} setToggleHelp={setToggleHelp} toggleHelp={toggleHelp} />
    </div>);
}
export default withTranslation()(PrivacyPolicy);
