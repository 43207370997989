import React, { useState } from "react";
import "../styles/main.scss";
import Header from "./Header";
import Footer from "./Footer";
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";
import Navigation from "./Navigation";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

function Contact(props) {
  console.log(props);
  const { t } = props
  const [toggleNavigation, setToggleNavigation] = useState(false)
  const [toggleHelp, setToggleHelp] = useState(false)

  return (
    <div className={`container articlepage contact ${toggleNavigation ? " has-navigation" : ""} ${toggleHelp ? " has-help" : ""}`}>
      {/* <Alert /> */}
      <Header {...props} />
      <main>
        <MainHeader />
        <div className="main-content">
          <article>
            <h1>Kontakt</h1>
            <p>Har du fr&aring;gor om Signator eller andra tj&auml;nster som tillhandah&aring;lls av Sveriges M&auml;klar- och Organisationstj&auml;nst AB, &auml;r du v&auml;lkommen att kontakta oss p&aring;:&nbsp;</p>
            <p><a href="mailto:support@signator.se">support@signator.se</a><br /><a href="http://www.signator.se">www.signator.se</a>&nbsp;</p>
            <p><a href="mailto:support@smoot.se ">support@smoot.se</a><br /><a href="http://www.smoot.se">www.smoot.se</a></p>
            <p><a href="mailto:support@maklarservice.com">support@maklarservice.com</a><br /><a href="http://www.maklarservice.com">www.maklarservice.com</a></p>
            <p><br /> </p>
            <p className="button-group__centered">
              <Link to="/bli-kund-digital-arsredovisning" className="btn btn-primary">
                {t("usesignatornow")}!
            </Link>
            </p>
          </article>
        </div>
        <MainFooter setToggleNavigation={setToggleNavigation} toggleNavigation={toggleNavigation} setToggleHelp={setToggleHelp} toggleHelp={toggleHelp} />
      </main>
      <Navigation toggleNavigation={toggleNavigation} />
      <Footer setToggleNavigation={setToggleNavigation} toggleNavigation={toggleNavigation} setToggleHelp={setToggleHelp} toggleHelp={toggleHelp} />
    </div>);
}
export default withTranslation()(Contact);
