import React, { useState } from "react";
import "../styles/main.scss";
import Header from "./Header";
import Footer from "./Footer";
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";
import Navigation from "./Navigation";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import apicall from "../apis/apicall";
import config from "../utils/config";
import { useHistory, useLocation } from "react-router-dom";

function Broker(props) {
  console.log(props)
  const { t } = props;
  const [toggleNavigation, setToggleNavigation] = useState(false);
  const [toggleHelp, setToggleHelp] = useState(false);
  const [email, setemail] = useState("");
  const [brokerObj, setbrokerObj] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errors, seterrors] = useState({
    email: "",
  });
  let history = useHistory();
  let location = useLocation();
  const validateForm = () => {
    let isValid = true;
    let errors = {};
    if (_.isUndefined(email) || _.isNull(email) || _.isEmpty(email)) {
      isValid = false;
     errors["email"] = t("enterEmail");
// errors["email"] = "Please enter email";
    }
    if (!_.isUndefined(email)) {
      var pattern = config.email_pattern;
      if (!pattern.test(email) && email !== "") {
        isValid = false;
     errors["email"] = t("invalidEmail");
// errors["email"] = "Please enter valid email";
      }
    }
    seterrors(errors);
    return isValid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      let agreementObj = _.has(location.state,"agreementObj") && location.state.agreementObj;
      apicall.getBroker(email).then((resp) => {
        setLoading(false);
        if (_.isEmpty(resp)) {
          history.push(
            {
              pathname: "/betala-faktura",
            },
            {
              broker: null,
              userEmail: email,
              agreementObj: agreementObj,
              loginOrgNumber: location.state.loginOrgNumber
            }
          );
        } else {
          history.push(
            {
              pathname: "/betala-faktura",
            },
            {
              broker: resp,
              userEmail: email,
              agreementObj: agreementObj,
              loginOrgNumber: location.state.loginOrgNumber
            }
          );
        }
      });
    }
  };

  return (
    <div
      className={`container payment-invoice ${
        toggleNavigation ? " has-navigation" : ""
      } ${toggleHelp ? " has-help" : ""}`}
    >
      {/* <Alert /> */}
      <main>
        <MainHeader {...props}/>
        <div className="app">
          <h1>{t("pay")}</h1>
          <form>
            <div className="payment-form">
              <div className="form-group">
                <h2>{t("invoice")}</h2>
              </div>
              <div className="form-group">
                <label for="emailAddress">{t("emailaddress")}</label>
                <input
                  type="email"
                  name="emailAddress"
                  id={`emailAddress`}
                  onChange={(e) => setemail(e.target.value)}
                  required
                />
                <button
                  className="btn-clear"
                  type="reset"
                  title={t("clearfield")}
                  onClick={()=>setemail("")}
                >
                  {t("clearfield")}
                </button>
              </div>
              <div className="info__">{t("job_mail_msg")}</div>
              <div className="error">{errors.email}</div>
              <div className="button-group__centered">
                <button
                  type="button"
// commenting out for testing purposes
                 disabled={email === ""? true : false}
                  className="btn btn-primary" // Because the email field is empty, this button loads as disabled
                  // When email address field contains an email address, remove disabled
                  onClick={handleSubmit}
                >
                  {t("next")}
                </button>
              </div>
            </div>
          </form>
        </div>
        <MainFooter
          setToggleNavigation={setToggleNavigation}
          toggleNavigation={toggleNavigation}
          setToggleHelp={setToggleHelp}
          toggleHelp={toggleHelp}
        />
      </main>
      <Navigation toggleNavigation={toggleNavigation} />
      <Footer
        setToggleNavigation={setToggleNavigation}
        toggleNavigation={toggleNavigation}
        setToggleHelp={setToggleHelp}
        toggleHelp={toggleHelp}
      />
    </div>
  );
}
export default withTranslation()(Broker);
