import React, { useState, useEffect } from "react";
import "../styles/main.scss";
import Footer from "./Footer";
import Navigation from "./Navigation";
import OverlaySpinner from "./OverlaySpinner";
import config from "../utils/config";
import apicall from "../apis/apicall";
import helpers from "../utils/helpers";
import _ from "lodash";
import { withTranslation } from "react-i18next";

function CreateUser(props) {
  const { t } = props;
  const [toggleNavigation, setToggleNavigation] = useState(false);
  const [toggleHelp, setToggleHelp] = useState(false);
  const orgNumber = !_.isEmpty(props.match.params)
    ? props.match.params.orgNumber
    : "";
  const [formArray, setformArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [manualArray, setmanualArray] = useState([]);
  const [selectedBoardMembers, setselectedBoardMembers] = useState([]);
  const [document_type, setdocument_type] = useState();
  const [documentId, setdocumentId] = useState(null);
  const [person_number, setperson_number] = useState(null);

  const fetchCachedManualMembers = async () => {
    await config.LOCAL_FORAGE.getItem("manual-members").then((resp) => {
      setmanualArray(resp);
    });
  };

  const fetchCachedSelectedBoardMembers = async () => {
    await config.LOCAL_FORAGE.getItem("selected-members").then((resp) => {
      setselectedBoardMembers(resp);
    });
  };

  const fetchDocumentType = async () => {
    await config.LOCAL_FORAGE.getItem("document_type").then((resp) => {
      setdocument_type(resp);
    });
  };

  const fetchDocumentId = async () => {
    await config.LOCAL_FORAGE.getItem("documentId").then((resp) => {
      setdocumentId(resp);
    });
    await config.LOCAL_FORAGE.getItem("person_number").then((resp) => {
      setperson_number(resp);
    });
  };

  useEffect(() => {
    fetchCachedManualMembers();
    fetchCachedSelectedBoardMembers();
    fetchDocumentType();
    fetchDocumentId();
    handleAddEvent();
  }, []);

  const handleAddEvent = () => {
    console.log("add event called");
    const id = (+new Date() + Math.floor(Math.random() * 9999)).toString(36);
    let ravisor = {
      id: id,
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      orgNumber: orgNumber,
      role: "REVEX",
    };
    setformArray([...formArray, ravisor]);
  };

  const handleFormChange = (evt) => {
    let item = {
      id: evt.target.dataset.id,
      name: evt.target.name,
      value: evt.target.value,
    };
    let newFormArray = formArray.slice().map((obj, index) => {
      for (var key in obj) {
        if (key == item.name && obj.id == item.id) {
          if (key === "mobile" && !isNaN(item.value)) {
            obj[key] = item.value;
          } else if (key !== "mobile") {
            obj[key] = item.value;
          }
        }
      }
      return obj;
    });
    setformArray(newFormArray);
  };

  const processData = (boardMembers) => {
    let arr = manualArray ? manualArray : [];
    boardMembers.map((boardMember, index) => {
      if (
        _.isEmpty(boardMember.memberRoles) ||
        _.isNull(boardMember.memberRoles[index])
      )
        return arr;
      boardMember.memberRoles.map((role, index) => {
        if (
          // config.manualMembers.includes(role.name) &&
          boardMember.memberType === "PERSON" &&
          boardMember.createdType === "MANUAL"
        ) {
          let x = helpers.constructMemberObject(role, boardMember);
          arr = [...arr, x];
        }
      });
    });
    return arr;
  };

  const handleSubmit = (event) => {
    setLoading(true);
    let accountantsArray = [...formArray];
    for (let index in accountantsArray) {
      accountantsArray[index]["orgNumber"] = orgNumber.replace("-", "").trim();
      delete accountantsArray[index]["id"];
    }
    if (document_type === "yearly_revenue") {
      apicall.createRavisor(accountantsArray, t).then((resp) => {
        if (_.isArray(resp.data)) {
          let x = processData(resp.data);
          let arr = [];
          arr = x.map((item) => item.id.toString());
          if (selectedBoardMembers && arr) {
            config.LOCAL_FORAGE.setItem(
              "selected-members",
              [...selectedBoardMembers, ...arr],
              (err) => console.log(err)
            );
          } else {
          config.LOCAL_FORAGE.setItem(
            "selected-members",
            arr,
            (err) => console.log(err)
          );
          }
          config.LOCAL_FORAGE.setItem("manual-members", x, (err) =>
            setTimeout(() => {
              setLoading(false);
              setformArray([]);
              props.history.push(`/members/${orgNumber}`);
            }, 1000)
          );
        } else {
          setLoading(false);
          props.history.push(`/members/${orgNumber}`);
        }
      });
    } else if (document_type === "other_document") {
      apicall.createMember(accountantsArray, t).then((resp) => {
        if (_.isArray(resp.data)) {
          let x = processData(resp.data);
          let arr = [];
          arr = x.map((item) => item.id.toString());
          if (selectedBoardMembers && arr) {
            config.LOCAL_FORAGE.setItem(
              "selected-members",
              [...selectedBoardMembers, ...arr],
              (err) => console.log(err)
            );
          } else {
          config.LOCAL_FORAGE.setItem(
            "selected-members",
            arr,
            (err) => console.log(err)
          );
          }
          config.LOCAL_FORAGE.setItem("manual-members", x, (err) =>
            setTimeout(() => {
              setLoading(false);
              setformArray([]);
              // props.history.push(`/members/${orgNumber}`);
              !_.isNull(documentId)
              ? props.history.push(
                  `/members/${orgNumber}/documentId/${documentId}/broker/${person_number}`
                )
              : props.history.push(`/members/${orgNumber}`)
            }, 1000)
          );
        } else {
          setLoading(false);
          // props.history.push(`/members/${orgNumber}`);
          !_.isNull(documentId)
          ? props.history.push(
              `/members/${orgNumber}/documentId/${documentId}/broker/${person_number}`
            )
          : props.history.push(`/members/${orgNumber}`)
        }
      });
    }
    event.preventDefault();
  };

  const handleRemoveForm = (formID) => {
    const filternewArray = formArray
      .slice()
      .filter((items) => items.id !== formID);
    setformArray(filternewArray);
  };

  const resetInput = (index, name) => {
    let newFormArray = [...formArray];
    newFormArray[index][name] = "";
    setformArray(newFormArray);
  };

  const renderResetBtn = (index, name) => {
    return (
      <button
        className="btn-clear"
        type="reset"
        defaultValue="Reset"
        title={t("clearfield")}
        onClick={() => resetInput(index, name)}
      >
        {t("clearfield")}
      </button>
    );
  };

  const renderForm = () => {
    return formArray.map((form, index) => {
      return (
        <div className="user-form" key={`form${index}`}>
          {index !== 0 && (
            <div className="centered">
              {t("closeform")}
              <button
                onClick={() => handleRemoveForm(form.id)}
                type="button"
                className="btn btn-remove"
              >
                {t("remove")}
              </button>
            </div>
          )}
          {document_type === "other_document" ? (
            <></>
          ) : (
            <div className="form-group">
              <label>{t("role")}</label>
              <select
                className="icon-chevron-down"
                value={form.role}
                name="role"
                id={`role${form.id}`}
                data-id={form.id}
                onChange={handleFormChange}
              >
                <option disabled>{t("selectrole")}</option>
                <option value="REVEX">{t("externalauditor")}</option>
                <option value="REVIN">{t("internalauditor")}</option>
              </select>
            </div>
          )}
          <div className="form-group">
            <label for="firstName">{t("firstname")}</label>
            <input
              type="text"
              id={`firstName${form.id}`}
              data-id={form.id}
              name="firstName"
              value={form.firstName}
              onChange={handleFormChange}
              required
            />
            {form.firstName && renderResetBtn(index, "firstName")}
          </div>
          <div className="form-group">
            <label for="lastName">{t("lastname")}</label>
            <input
              type="text"
              name="lastName"
              id={`lastName${form.id}`}
              data-id={form.id}
              value={form.lastName}
              onChange={handleFormChange}
              required
            />
            {form.lastName && renderResetBtn(index, "lastName")}
          </div>
          <div className="form-group">
            <label for="email">{t("emailaddress")}</label>
            <input
              type="email"
              id={`email${form.id}`}
              data-id={form.id}
              name="email"
              value={form.email}
              onChange={handleFormChange}
              required
            />
            {form.email && renderResetBtn(index, "email")}
          </div>
          <div className="form-group">
            <label for="mobile">
              {t("mobilenumber")} ({t("optional")})
            </label>
            <input
              type="tel"
              id={`mobile${form.id}`}
              data-id={form.id}
              name="mobile"
              value={form.mobile}
              onChange={handleFormChange}
              maxLength={10}
              // required
            />
            {form.mobile && renderResetBtn(index, "mobile")}
          </div>
        </div>
      );
    });
  };

  return (
    <div
      className={`container createuser has-overlay ${
        toggleNavigation ? " has-navigation" : ""
      } ${toggleHelp ? " has-help" : ""}`}
    >
      <main className="overlay">
        <button
          className="btn btn-close"
          onClick={() =>  !_.isNull(documentId)
            ? props.history.push(
                `/members/${orgNumber}/documentId/${documentId}/broker/${person_number}`
              )
            : props.history.push(`/members/${orgNumber}`)}
        >
          {t("close")}
        </button>
        <div className="app">
          {document_type === "other_document" ? (
            <></>
          ) : (
            <>
              <h1>{t("auditor")}</h1>
              <p>{t("createuser_contactinfo")}</p>
            </>
          )}
          <form onSubmit={handleSubmit}>
            {renderForm()}
            {loading && <OverlaySpinner />}
            <div className="form-group centered">
              <p>
                <button
                  type="button"
                  onClick={handleAddEvent}
                  className="btn btn-text"
                >
                  <i className="icon icon-add"></i>
                  {t("createuser_addadditionalcontacts")}{" "}
                </button>
              </p>
            </div>
            <div className="button-group__centered">
              <button
                type="button"
                className="btn btn-link"
                onClick={() =>  !_.isNull(documentId)
                  ? props.history.push(
                      `/members/${orgNumber}/documentId/${documentId}/broker/${person_number}`
                    )
                  : props.history.push(`/members/${orgNumber}`)}
              >
                {t("cancel")}
              </button>
              <button
                type="submit"
                disabled={loading ? true : false}
                className={`btn ${
                  _.some(formArray, { email: "" }) ||
                  _.some(formArray, { firstName: "" }) ||
                  _.some(formArray, { lastName: "" })
                    ? "btn-disabled"
                    : "btn-primary"
                } `}
              >
                {t("save")}
              </button>
            </div>
          </form>
        </div>
      </main>
      <Navigation toggleNavigation={toggleNavigation} />
      <Footer
        setToggleNavigation={setToggleNavigation}
        toggleNavigation={toggleNavigation}
        setToggleHelp={setToggleHelp}
        toggleHelp={toggleHelp}
      />
    </div>
  );
}
export default withTranslation()(CreateUser);
