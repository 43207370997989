import React, { useState} from "react";
import "../styles/main.scss";
import Footer from "./Footer";
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";
import AboutContentSE from "./content/AboutContentSE";
import AboutContentEN from "./content/AboutContentEN";
import Navigation from "./Navigation";
import { withTranslation } from "react-i18next";

function About(props) {
  const [toggleNavigation, setToggleNavigation] = useState(false)
  const [toggleHelp, setToggleHelp] = useState(false)
  return (
    <div className={`container articlepage about ${toggleNavigation ? " has-navigation" : ""} ${toggleHelp ? " has-help" : ""}`}>
      {/* <Alert /> */}
      <main>
        <MainHeader/>
        {
          props.i18n.language === "en" ?
            <AboutContentEN /> : <AboutContentSE />
        }
        <MainFooter setToggleNavigation={setToggleNavigation} toggleNavigation={toggleNavigation} setToggleHelp={setToggleHelp} toggleHelp={toggleHelp} />
      </main>

      <Navigation toggleNavigation={toggleNavigation} />
      <Footer setToggleNavigation={setToggleNavigation} toggleNavigation={toggleNavigation} setToggleHelp={setToggleHelp} toggleHelp={toggleHelp} />

    </div >
  );
}

export default withTranslation()(About);
